import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormText from './LoginFormText';
import LoginFormInput from './LoginFormInput';
import LoginFormButton from './LoginFormButton';
import LoginFormFooterLink from './LoginFormFooterLink';
import LoginFormFooter from './LoginFormFooter';
import { SCREEN_RESET_LOGIN } from './ScreenState';
import { bindCache } from 'sg/util/react_helpers';

class ResetPasswordForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
        };
        this.bind = bindCache(this);
    }

    handleEmailRequest(e) {
        if (e.target instanceof HTMLFormElement) {
            e.preventDefault();
        }
        if (this.state.login) {
            this.props.onEmailRequest(this.state.login);
        }
    }

    handleInput(value) {
        this.setState({
            login: value,
        });
    }

    handleForgotLogin() {
        this.props.setLoginState({
            screenState: SCREEN_RESET_LOGIN,
        });
    }

    render() {
        return (
            <form onSubmit={this.bind(this.handleEmailRequest)}>
                <LoginFormText>
                    {i18next.t('components.login.forgot_password_message')}
                </LoginFormText>
                <LoginFormInput
                    label={i18next.t('components.login.login_email_label')}
                    id="user_login"
                    value={this.state.login}
                    name="user[login]"
                    onChange={this.bind(this.handleInput)}
                />
                <LoginFormButton name="commit" type="button" onClick={this.bind(this.handleEmailRequest)}>
                    {i18next.t('components.login.password_reset_button')}
                </LoginFormButton>
                <LoginFormFooter>
                    <LoginFormFooterLink onClick={this.bind(this.handleForgotLogin)}>
                        {i18next.t('components.login.forgot_login')}
                    </LoginFormFooterLink>
                </LoginFormFooter>
            </form>
        );
    }
}

ResetPasswordForm.propTypes = {
    onEmailRequest: PropTypes.func.isRequired,
    setLoginState: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
