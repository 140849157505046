import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormText from './LoginFormText';
import LoginFormInput from './LoginFormInput';
import LoginFormButton from './LoginFormButton';
import LoginFormFooterLink from './LoginFormFooterLink';
import LoginFormFooter from './LoginFormFooter';
import ForgotLoginPasswordButton from './ForgotLoginPasswordButton';

import { SCREEN_RESET_PASSWORD } from './ScreenState';

class AutodeskIdentityLinkForm extends PureComponent {
    render() {
        const { csrfToken, oldLogin, setLoginState, firstAccountLink } = this.props;
        let infoText = i18next.t('components.login.autodesk_identity_link_message_2');
        if (firstAccountLink) {
            infoText = i18next.t('components.login.autodesk_identity_link_message') + infoText;
        }
        return (
            <form method="post">
                <LoginFormText>
                    {infoText}
                </LoginFormText>
                <LoginFormInput
                    label={i18next.t('components.login.login_label')}
                    id="user_login"
                    name="old_login"
                    defaultValue={oldLogin}
                />
                <LoginFormInput
                    label={i18next.t('components.login.password_label')}
                    id="user_password"
                    name="old_password"
                    type="password"
                />
                <LoginFormButton name="commit" type="submit">
                    {i18next.t('components.login.link_account_button')}
                </LoginFormButton>
                <input type="hidden" name="csrf_token" defaultValue={csrfToken} />
                <LoginFormFooter>
                    <ForgotLoginPasswordButton loginState={SCREEN_RESET_PASSWORD} setLoginState={setLoginState} />
                    {!firstAccountLink &&
                        <LoginFormFooterLink
                            style={{
                                display: 'block',
                            }}
                            href="/autodesk_identity/select"
                        >
                            {i18next.t('components.login.select_linked_account')}
                        </LoginFormFooterLink>}
                </LoginFormFooter>
            </form>
        );
    }
}

AutodeskIdentityLinkForm.propTypes = {
    csrfToken: PropTypes.string.isRequired,
    oldLogin: PropTypes.string,
    setLoginState: PropTypes.func.isRequired,
    firstAccountLink: PropTypes.bool.isRequired,
};

export default AutodeskIdentityLinkForm;
