import React from 'react';
import ShotgunDesignLanguageContext from '../../sgds/ShotgunDesignLanguageContext';

export default component => {
    function UsingDesignLanguageContext(props) {
        return (
            <ShotgunDesignLanguageContext.Consumer>
                {designLanguageContext => React.createElement(component, { ...props, designLanguageContext })}
            </ShotgunDesignLanguageContext.Consumer>
        );
    }
    UsingDesignLanguageContext.displayName = `withDesignLanguageContext(${component.displayName || component.name})`;
    return UsingDesignLanguageContext;
};
