import { designLanguage, DesignLanguage } from '@sgds/react';

import shotgunRoles from 'sg/sgds/shotgun_roles';

const { unresolvedRolesByTheme } = designLanguage;

export const shotgunDesignLanguage = new DesignLanguage({
    ...designLanguage.toJS(),
    unresolvedRolesByTheme: {
        DEFAULT: {
            ...unresolvedRolesByTheme.DEFAULT,
            ...shotgunRoles.DEFAULT,
        },
        BLACK: {
            ...unresolvedRolesByTheme.BLACK,
            ...shotgunRoles.DEFAULT,
            ...shotgunRoles.BLACK,
        },
    },
});
