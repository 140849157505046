import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from '@sgds/react';

import LoginFormText from './LoginFormText';
import LoginFormButton from './LoginFormButton';
import AccountChoiceContainer from './AccountChoiceContainer';
import ScrollableContent from './ScrollableContent';
import { bindCache } from 'sg/util/react_helpers';

export default class SamlNoMatchAccountLinkForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hasAccount: undefined,
        };
        this.bind = bindCache(this);
    }

    selectHasAccount(hasAccount) {
        this.setState({
            hasAccount,
        });
    }

    render() {
        const { samlEmail, samlLogin } = this.props;
        const { hasAccount } = this.state;
        return (
            <form action="/saml/verify_link" method="post">
                <LoginFormText>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: i18next.t('components.login.no_match_text', {
                                markup_line_break: '<br/><br/>',
                            }),
                        }}
                    />
                </LoginFormText>
                <ScrollableContent>
                    {[true, false].map(value =>
                        <SamlHasAccountChoice
                            key={value.toString()}
                            hasAccountChoice={value}
                            selected={hasAccount === value}
                            onSelected={this.bind(this.selectHasAccount, value)}
                        />
                    )}
                </ScrollableContent>
                {hasAccount !== true &&
                    <LoginFormButton
                        onClick={() => (window.location.pathname = '/saml/saml_linking_skip')}
                        isDisabled={hasAccount !== false}
                    >
                        {i18next.t('components.login.sign_in_button')}
                    </LoginFormButton>}
                {hasAccount === true &&
                    <LoginFormButton name="commit" type="submit">
                        {i18next.t('components.login.find_account_button')}
                    </LoginFormButton>}
                <input type="hidden" name="ignore_browser_check" defaultValue="1" />
                <input type="hidden" name="saml_login" defaultValue={samlLogin} />
                <input type="hidden" name="saml_email" defaultValue={samlEmail} />
            </form>
        );
    }
}

SamlNoMatchAccountLinkForm.propTypes = {
    samlEmail: PropTypes.string.isRequired,
    samlLogin: PropTypes.string.isRequired,
};

class SamlHasAccountChoice extends PureComponent {
    render() {
        const { hasAccountChoice, selected, onSelected } = this.props;
        return (
            <AccountChoiceContainer selected={selected} onSelected={onSelected}>
                <input
                    style={{ position: 'fixed', opacity: 0 }}
                    checked={selected}
                    type="radio"
                    name="link"
                    defaultValue={hasAccountChoice ? 'yes' : 'no'}
                />
                <Text>
                    {hasAccountChoice &&
                        i18next.t('components.login.already_have_option', {
                            site: SG.globals.hostname,
                        })}
                    {!hasAccountChoice &&
                        i18next.t('components.login.my_first_option', {
                            site: SG.globals.hostname,
                        })}
                </Text>
            </AccountChoiceContainer>
        );
    }
}

SamlHasAccountChoice.propTypes = {
    hasAccountChoice: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    onSelected: PropTypes.func.isRequired,
};
