import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TextInput, TextField, unstyled } from '@sgds/react';

import withDesignLanguageContext from './withDesignLanguageContext';

import { memoize } from 'sg/util/react_helpers';
import { loginWidth, useOldBrowserStyles } from './styleConstants';

import getLoginFormInputStyleSheet from './getLoginFormInputStyleSheet';

const autofillActivateEvent = 'autofillActivate';

function getAutofillRule(pseudoSelector) {
    return `
    input:${pseudoSelector} {
        animation-name: ${autofillActivateEvent};
        transition: background-color 0s;
    }
    `;
}

const cssForAutofill = `
    @keyframes ${autofillActivateEvent} {
        from {/**/}
        to {/**/}
    }
    ${getAutofillRule('autofill')}
    ${getAutofillRule('-webkit-autofill')}
    ${getAutofillRule('-moz-autofill')}
`;

let addedCss = false;
function addCssForAutofill() {
    if (addedCss) {
        return;
    }
    const style = document.createElement('style');
    style.innerHTML = cssForAutofill;
    document.body.appendChild(style);
    addedCss = true;
}

const inputStyle = memoize(marginTop => ({
    marginTop,
    width: loginWidth,
    minWidth: 'initial',
}));

// NOTE: only used if `useOldBrowserStyles`
function renderInputElement({ style, ...props }) {
    return <unstyled.input type="text" style={{ ...style, width: loginWidth }} {...props} />;
}

// NOTE: only used if `useOldBrowserStyles`
function renderInput({ style, ...props }) {
    return <TextInput renderInput={renderInputElement} style={{ ...style, width: loginWidth }} {...props} />;
}

class LoginFormInput extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            forceExpandForAutofill: false,
        };
    }

    componentDidMount() {
        addCssForAutofill();
        this.input.addEventListener('animationstart', e => {
            if (e.animationName === autofillActivateEvent) {
                this.setState({ forceExpandForAutofill: true });
            }
        });
    }

    render() {
        const { designLanguageContext: { basicsValues }, onChange, ...rest } = this.props;
        return (
            <TextField
                {...rest}
                styleSheetFactoryFunc={getLoginFormInputStyleSheet(this.state.forceExpandForAutofill)}
                onChange={value => {
                    if (onChange) {
                        onChange(value);
                    }
                    this.setState({ forceExpandForAutofill: false });
                }}
                ref={elem => (this.input = elem)}
                renderTextInput={useOldBrowserStyles ? renderInput : undefined}
                style={inputStyle(basicsValues.spacing.SMALL)}
            />
        );
    }
}

LoginFormInput.propTypes = {
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(LoginFormInput);
