import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@sgds/react';

import withDesignLanguageContext from './withDesignLanguageContext';

import { memoize } from 'sg/util/react_helpers';
import { loginWidth, useOldBrowserStyles } from './styleConstants';

const buttonStyle = memoize((marginVertical, fontSize, fontFamily) => ({
    marginTop: marginVertical,
    marginBottom: marginVertical,
    width: loginWidth,
    fontFamily,
    fontSize,
    // RV webkit fix:
    ...(useOldBrowserStyles && {
        display: 'inline-block',
        textAlign: 'center',
    }),
}));

class LoginFormButton extends PureComponent {
    render() {
        const { isMinor, type, designLanguageContext: { basicsValues }, onClick, ...rest } = this.props;
        return (
            <Button
                {...rest}
                type={type}
                variant={isMinor ? Button.variants.SECONDARY : Button.variants.PRIMARY}
                style={buttonStyle(
                    basicsValues.spacing.LARGE,
                    basicsValues.fontSize.BASE,
                    basicsValues.fontFamily.BASE
                )}
                onClick={onClick}
            />
        );
    }
}

LoginFormButton.propTypes = {
    isMinor: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

LoginFormButton.defaultProps = {
    isMinor: false,
    type: 'button',
    onClick: () => {},
};

export default withDesignLanguageContext(LoginFormButton);
