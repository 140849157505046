import React, { PureComponent, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { Text, TextLink, designLanguage } from '@sgds/react';

import AutodeskIdentityLinkForm from './AutodeskIdentityLinkForm';
import AutodeskIdentityLoginForm from './AutodeskIdentityLoginForm';
import AutodeskIdentitySelectForm from './AutodeskIdentitySelectForm';
import FlashMessage from './FlashMessage';
import GettingHelp from './GettingHelp';
import GettingHelpDone from './GettingHelpDone';
import LoginFormButton from './LoginFormButton';
import PasswordLoginForm from './PasswordLoginForm';
import ResetLoginDone from './ResetLoginDone';
import ResetLoginForm from './ResetLoginForm';
import ResetPasswordDone from './ResetPasswordDone';
import ResetPasswordForm from './ResetPasswordForm';
import SamlAccountLinkForm from './SamlAccountLinkForm';
import SamlAccountLinkPasswordForm from './SamlAccountLinkPasswordForm';
import SamlAccountMergeProgress from './SamlAccountMergeProgress';
import SamlLoginForm from './SamlLoginForm';
import SamlNoMatchAccountLinkForm from './SamlNoMatchAccountLinkForm';
import TwoFactorLoginForm from './TwoFactorLoginForm';
import UnapprovedBrowserNotice from './UnapprovedBrowserNotice';

import * as ScreenState from './ScreenState';
import { loginWidth } from './styleConstants';

import { bindCache } from 'sg/util/react_helpers';

import ShotgunDesignLanguageContext from '../../sgds/ShotgunDesignLanguageContext';

const {
    SCREEN_SAML_LOGIN,
    SCREEN_SAML_LINK,
    SCREEN_SAML_NO_MATCH_LINK,
    SCREEN_SAML_LINK_PASSWORD,
    SCREEN_SAML_ACCOUNT_MERGE_PROGRESS,
    SCREEN_AUTODESK_IDENTITY_LOGIN,
    SCREEN_AUTODESK_IDENTITY_LINK,
    SCREEN_AUTODESK_IDENTITY_SELECT,
    SCREEN_TWO_FACTOR_LOGIN,
    SCREEN_RESET_PASSWORD,
    SCREEN_RESET_PASSWORD_DONE,
    SCREEN_RESET_LOGIN,
    SCREEN_RESET_LOGIN_DONE,
    SCREEN_PASSWORD_LOGIN,
    SCREEN_GETTING_HELP,
    SCREEN_GETTING_HELP_DONE,
} = ScreenState;

function useDarkTheme() {
    return SG.globals.theme === 'dark';
}

class LoginView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            screenState: props.initialScreenState,
            flashNotice: props.initialFlashNotice,
            flashWarning: props.initialFlashWarning,
            loginInputWarning: props.initialLoginInputWarning,
            flashError: props.initialFlashError,
            containerHeight: undefined,
        };
        this.contentRef = createRef();
        this.bind = bindCache(this);
    }

    componentDidMount() {
        this.setFocusOnFirstInput();
        const testDiv = document.createElement('div');
        testDiv.style.height = '100vh';
        // if vh isn't supported then let's force it
        if (!testDiv.style.height) {
            this.setState({
                containerHeight: window.innerHeight,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.screenState !== this.state.screenState) {
            this.setFocusOnFirstInput();
            this.setState({
                flashNotice: null,
                flashWarning: null,
                loginInputWarning: null,
                flashError: null,
            });
        }
    }

    setFocusOnFirstInput() {
        // Find the first text or password input and set focus on it
        const inputs = this.contentRef.current.querySelectorAll('input:not(:disabled)');
        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];
            if (['text', 'password'].indexOf(input.getAttribute('type')) > -1) {
                input.focus();
                break;
            }
        }
    }

    handleResetPasswordRequest(login) {
        this.handleEmailRequest(login, '/user/send_reset_password_email', SCREEN_RESET_PASSWORD_DONE);
    }

    handleResetLoginRequest(email) {
        this.handleEmailRequest(email, '/user/send_login_info_email', SCREEN_RESET_LOGIN_DONE);
    }

    handleEmailRequest(value, page, successScreenState) {
        //  Request might want "login" or "email", so just set both
        var request = {
            url: page,
            params: {
                login: value,
                email: value,
            },
            promise_scope: this,
        };

        sg_send_request(request).then(
            function success(serverResponse) {
                var response = JSON.parse(serverResponse.response);

                if (response.successful) {
                    this.setState({
                        screenState: successScreenState,
                    });
                } else {
                    this.setState({
                        flashError: response.error,
                    });
                }
            },
            function error(serverResponse) {
                SG.server_error({ connection_response: serverResponse });
            }
        );
    }

    renderLoginContent() {
        const {
            samlLogin,
            samlEmail,
            oldLogin,
            samlUserId,
            samlAccounts,
            autodeskAccounts,
            csrfToken,
            samlMergeProgress,
            firstAccountLink,
        } = this.props;

        const common = {
            setLoginState: this.bind(this.setState),
        };

        switch (this.state.screenState) {
            case SCREEN_SAML_LINK_PASSWORD:
                return (
                    <SamlAccountLinkPasswordForm
                        {...common}
                        samlLogin={samlLogin}
                        samlEmail={samlEmail}
                        oldLogin={oldLogin}
                    />
                );
            case SCREEN_SAML_ACCOUNT_MERGE_PROGRESS:
                return <SamlAccountMergeProgress {...common} initialProgress={samlMergeProgress} userId={samlUserId} />;
            case SCREEN_SAML_LINK: {
                return (
                    <SamlAccountLinkForm
                        {...common}
                        samlAccounts={samlAccounts}
                        samlLogin={samlLogin}
                        samlEmail={samlEmail}
                    />
                );
            }
            case SCREEN_SAML_NO_MATCH_LINK:
                return <SamlNoMatchAccountLinkForm {...common} samlLogin={samlLogin} samlEmail={samlEmail} />;
            case SCREEN_SAML_LOGIN:
                return <SamlLoginForm {...common} />;
            case SCREEN_AUTODESK_IDENTITY_LOGIN: {
                const searchParams = new URL(window.location.href).searchParams;
                return (
                    <AutodeskIdentityLoginForm
                        {...common}
                        email={searchParams.get('email')}
                        oldLogin={searchParams.get('old_login') || oldLogin}
                    />
                );
            }
            case SCREEN_AUTODESK_IDENTITY_LINK: {
                const searchParams = new URL(window.location.href).searchParams;
                return (
                    <AutodeskIdentityLinkForm
                        {...common}
                        csrfToken={csrfToken}
                        firstAccountLink={firstAccountLink}
                        oldLogin={searchParams.get('old_login') || oldLogin}
                    />
                );
            }
            case SCREEN_AUTODESK_IDENTITY_SELECT:
                return <AutodeskIdentitySelectForm {...common} csrfToken={csrfToken} accountInfos={autodeskAccounts} />;
            case SCREEN_TWO_FACTOR_LOGIN:
                return <TwoFactorLoginForm {...common} />;
            case SCREEN_RESET_PASSWORD_DONE:
                return <ResetPasswordDone {...common} />;
            case SCREEN_RESET_PASSWORD:
                return <ResetPasswordForm {...common} onEmailRequest={this.bind(this.handleResetPasswordRequest)} />;
            case SCREEN_RESET_LOGIN_DONE:
                return <ResetLoginDone {...common} />;
            case SCREEN_RESET_LOGIN:
                return <ResetLoginForm {...common} onEmailRequest={this.bind(this.handleResetLoginRequest)} />;
            case SCREEN_GETTING_HELP_DONE:
                return <GettingHelpDone {...common} />;
            case SCREEN_GETTING_HELP:
                return <GettingHelp {...common} />;
            case SCREEN_PASSWORD_LOGIN:
                return <PasswordLoginForm {...common} loginInputWarning={this.state.loginInputWarning} />;
        }
    }

    render() {
        const {
            showAlternateLogin,
            isBrowserNotApproved,
            isBrowserNotSupported,
            autodeskIdentityUserInfo,
        } = this.props;

        const { screenState, flashNotice, flashWarning, flashError, containerHeight } = this.state;

        let buildNumber = SG.globals.shotgun_version;
        if (SG.globals.shotgun_version_branch) {
            buildNumber += ' ' + SG.globals.shotgun_version_branch;
        }

        const subtitleHTML = ScreenState.getTitle({ screenState, autodeskIdentityUserInfo });

        return (
            <ShotgunDesignLanguageContext.Provider
                themeId={designLanguage.themeIds[useDarkTheme() ? 'BLACK' : 'DEFAULT']}
            >
                <ShotgunDesignLanguageContext.Consumer>
                    {({ basicsValues, roleValues }) =>
                        <div
                            style={{
                                fontSize: basicsValues.fontSize.BASE_PX_DEFAULT,
                                color: roleValues.COLOR_TEXT_PRIMARY,
                                height: containerHeight || '100vh',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: loginWidth,
                                fontFamily: basicsValues.fontFamily.BASE,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div />
                            <main>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <img
                                        alt="SHOTGUN"
                                        src={
                                            useDarkTheme()
                                                ? '/images/logos/shotgun-classic-logo-rgb-white-medium-28.svg'
                                                : '/images/logos/shotgun-classic-logo-rgb-black-28.svg'
                                        }
                                    />
                                </div>
                                <Text
                                    variant={Text.variants.HEADLINE_2}
                                    style={{
                                        fontSize: basicsValues.sizing.MEDIUM,
                                    }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: subtitleHTML }} />
                                </Text>
                                {(flashNotice || flashWarning || flashError) &&
                                    <Fragment>
                                        {flashNotice &&
                                            <FlashMessage variant="notice">
                                                {flashNotice}
                                            </FlashMessage>}
                                        {flashWarning &&
                                            <FlashMessage variant="warning">
                                                {flashWarning}
                                            </FlashMessage>}
                                        {flashError &&
                                            <FlashMessage variant="error">
                                                {flashError}
                                            </FlashMessage>}
                                    </Fragment>}
                                <div ref={this.contentRef}>
                                    {this.renderLoginContent()}
                                </div>
                                {ScreenState.hasBackToLoginButton(screenState) &&
                                    <LoginFormButton
                                        isMinor
                                        onClick={() => (window.location.pathname = '/user/login')}
                                        label={i18next.t('components.login.back_to_login')}
                                    />}
                                {isBrowserNotApproved &&
                                    <UnapprovedBrowserNotice isBrowserNotSupported={isBrowserNotSupported} />}
                            </main>
                            <footer>
                                <div
                                    id="shotgun_version"
                                    style={{
                                        fontStyle: 'italic',
                                        letterSpacing: '.04em',
                                        marginBottom: basicsValues.spacing.SMALL,
                                    }}
                                >
                                    {showAlternateLogin &&
                                        <Fragment>
                                            <TextLink
                                                id="alternate_login"
                                                href={`/user/force_default_login${window.location.search}`}
                                            >
                                                Site Administration
                                            </TextLink>{' '}
                                        </Fragment>}
                                    <Text
                                        style={{
                                            color: roleValues.COLOR_TEXT_SUBTLE,
                                            fontSize: basicsValues.fontSize.SMALL,
                                        }}
                                    >
                                        {buildNumber}
                                    </Text>
                                </div>
                            </footer>
                        </div>}
                </ShotgunDesignLanguageContext.Consumer>
            </ShotgunDesignLanguageContext.Provider>
        );
    }
}

LoginView.propTypes = {
    initialScreenState: PropTypes.string.isRequired,
    showAlternateLogin: PropTypes.bool.isRequired,
    initialFlashNotice: PropTypes.string,
    initialFlashWarning: PropTypes.string,
    initialLoginInputWarning: PropTypes.string,
    initialFlashError: PropTypes.string,
    isBrowserNotApproved: PropTypes.bool.isRequired,
    isBrowserNotSupported: PropTypes.bool.isRequired,
    samlLogin: PropTypes.string,
    samlEmail: PropTypes.string,
    oldLogin: PropTypes.string,
    samlUserId: PropTypes.number,
    samlAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            login: PropTypes.string.isRequired,
            image_url: PropTypes.string,
        }).isRequired
    ),
    autodeskAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            image_url: PropTypes.string,
            role: PropTypes.string.isRequired,
        }).isRequired
    ),
    csrfToken: PropTypes.string,
    samlMergeProgress: PropTypes.number,
    autodeskIdentityUserInfo: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
    }),
    firstAccountLink: PropTypes.bool,
};

export default LoginView;
