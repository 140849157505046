import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput, unstyled } from '@sgds/react';

import withDesignLanguageContext from './withDesignLanguageContext';
import { loginWidth, useOldBrowserStyles } from './styleConstants';

import { memoize } from 'sg/util/react_helpers';

const codeInputStyle = memoize((fontFamily, fontSize, marginTop) => ({
    fontFamily,
    fontSize,
    marginTop,
    marginLeft: 'auto',
    marginRight: 'auto',
    letterSpacing: '0.15em',
    width: '10rem',
    minWidth: 'initial',
}));

// NOTE: only used if `useOldBrowserStyles`
function renderInput({ style, ...props }) {
    return <unstyled.input type="text" style={{ ...style, width: loginWidth }} {...props} />;
}

class LoginFormCodeInput extends PureComponent {
    render() {
        const { designLanguageContext, ...rest } = this.props;
        const { basicsValues } = designLanguageContext;
        return (
            <TextInput
                {...rest}
                style={codeInputStyle(
                    basicsValues.fontFamily.MONOSPACE,
                    basicsValues.fontSize.XX_LARGE,
                    basicsValues.spacing.SMALL
                )}
                autoComplete="off"
                renderInput={useOldBrowserStyles ? renderInput : undefined}
            />
        );
    }
}

LoginFormCodeInput.propTypes = {
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(LoginFormCodeInput);
