import React, { PureComponent, Fragment } from 'react';

import LoginFormText from './LoginFormText';

class ResetPasswordDone extends PureComponent {
    render() {
        return (
            <Fragment>
                <LoginFormText>
                    {i18next.t('components.login.forgot_password_done_email_sent')}
                </LoginFormText>
                <LoginFormText>
                    {i18next.t('components.login.forgot_password_done_email_click')}
                </LoginFormText>
            </Fragment>
        );
    }
}

export default ResetPasswordDone;
