import React, { PureComponent } from 'react';

import LoginFormText from './LoginFormText';

class ResetLoginDone extends PureComponent {
    render() {
        return (
            <LoginFormText>
                {i18next.t('components.login.forgot_login_done_emailed')}
            </LoginFormText>
        );
    }
}

export default ResetLoginDone;
