import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TextLink } from '@sgds/react';
import withDesignLanguageContext from './withDesignLanguageContext';

import { memoize } from 'sg/util/react_helpers';

const linkStyle = memoize((fontSize, fontFamily) => ({
    fontSize,
    fontFamily,
}));

class LoginFormFooterLink extends PureComponent {
    render() {
        const { designLanguageContext, style, ...rest } = this.props;
        const { basicsValues } = designLanguageContext;
        return (
            <TextLink
                {...rest}
                style={Object.assign({}, linkStyle(basicsValues.fontSize.X_SMALL, basicsValues.fontFamily.BASE), style)}
            />
        );
    }
}

LoginFormFooterLink.propTypes = {
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(LoginFormFooterLink);
