import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ScrollableContent from './ScrollableContent';
import LinkedAccountChoice from './LinkedAccountChoice';

class LinkedAccountChoiceSet extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: props.accounts[0].inputValue,
        };
        this.onLinkedAccountSelect = this.onLinkedAccountSelect.bind(this);
    }

    render() {
        const { inputName, accounts, autoFocus } = this.props;
        const { selectedValue } = this.state;
        return (
            <ScrollableContent>
                {accounts.map(({ inputValue, text, subText, image }, index) =>
                    <LinkedAccountChoice
                        key={inputValue}
                        inputName={inputName}
                        inputValue={inputValue}
                        text={text}
                        subText={subText}
                        image={image}
                        selected={selectedValue === inputValue}
                        autoFocus={autoFocus && index === 0}
                        onSelected={this.onLinkedAccountSelect}
                    />
                )}
            </ScrollableContent>
        );
    }

    onLinkedAccountSelect(component) {
        this.setState({ selectedValue: component.props.inputValue });

        // Focus back on input (allows keyboard)
        let containerNode = ReactDOM.findDOMNode(component);
        let input = containerNode.querySelector('input[type=radio]');
        if (input) {
            input.focus();
        }

        // Adjust viewport
        let scrollViewNode = containerNode.parentNode;
        let containerRect = containerNode.getBoundingClientRect();
        let viewRect = scrollViewNode.getBoundingClientRect();
        if (containerRect.top < viewRect.top) {
            containerNode.scrollIntoView(true);
        } else if (containerRect.bottom > viewRect.bottom) {
            containerNode.scrollIntoView(false);
        }
    }
}

LinkedAccountChoiceSet.propTypes = {
    inputName: PropTypes.string.isRequired,
    accounts: PropTypes.arrayOf(
        PropTypes.shape({
            inputValue: PropTypes.number.isRequired,
            text: PropTypes.string.isRequired,
            subText: PropTypes.string.isRequired,
            image: PropTypes.string,
        })
    ),
    autoFocus: PropTypes.bool,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default LinkedAccountChoiceSet;
