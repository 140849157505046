import React from 'react';
import PropTypes from 'prop-types';

import LoginView from './LoginView';

const Login = ({ screenState, config }) => {
    const {
        autodesk_identity,
        autodesk_identity_user_info,
        browser_not_approved,
        csrf_token,
        first_link,
        flash_error,
        flash_notice,
        flash_warning,
        login_warning_label,
        matching_users,
        merge_in_progress,
        old_login,
        saml,
        saml_email,
        saml_login,
        saml_matching_accounts,
        unsupported_browser,
        user_id,
    } = config;

    return (
        <React.Fragment>
            <LoginView
                initialScreenState={screenState}
                initialFlashNotice={flash_notice}
                initialFlashWarning={flash_warning}
                initialLoginInputWarning={login_warning_label}
                initialFlashError={flash_error}
                isBrowserNotApproved={Boolean(browser_not_approved)}
                isBrowserNotSupported={Boolean(unsupported_browser)}
                showAlternateLogin={Boolean(saml || autodesk_identity)}
                samlLogin={saml_login}
                samlEmail={saml_email}
                oldLogin={old_login}
                samlUserId={user_id}
                samlAccounts={saml_matching_accounts}
                autodeskAccounts={matching_users}
                csrfToken={csrf_token}
                samlMergeProgress={merge_in_progress}
                autodeskIdentityUserInfo={autodesk_identity_user_info}
                firstAccountLink={first_link}
            />
        </React.Fragment>
    );
};

Login.propTypes = {
    screenState: PropTypes.string,
    config: PropTypes.object.isRequired,
};

Login.defaultProps = {
    screenState: undefined,
};

export default Login;
