import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormInput from './LoginFormInput';
import LoginFormButton from './LoginFormButton';
import LoginFormFooter from './LoginFormFooter';
import ForgotLoginPasswordButton from './ForgotLoginPasswordButton';

import { bindCache } from 'sg/util/react_helpers';

import { SCREEN_RESET_PASSWORD } from './ScreenState';

const submittedButtonStyle = {
    cursor: 'wait',
};

class PasswordLoginForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
        };
        this.bind = bindCache(this);
    }

    handleSubmit(e) {
        if (this.__submitted) {
            e.preventDefault();
            return;
        }
        this.__submitted = true;
        this.setState({
            submitted: true,
        });
    }

    render() {
        const { submitted } = this.state;
        return (
            <form action="/user/login" method="post" onSubmit={this.bind(this.handleSubmit)}>
                <LoginFormInput
                    instructionText={this.props.loginInputWarning}
                    label={i18next.t('components.login.login_label')}
                    id="user_login"
                    name="user[login]"
                />
                <LoginFormInput
                    label={i18next.t('components.login.password_label')}
                    id="user_password"
                    name="user[password]"
                    type="password"
                />
                <LoginFormButton
                    isDisabled={submitted}
                    style={submitted ? submittedButtonStyle : undefined}
                    name="commit"
                    type="submit"
                >
                    {i18next.t('components.login.sign_in_button')}
                </LoginFormButton>
                <input type="hidden" name="ignore_browser_check" defaultValue="1" />
                <LoginFormFooter>
                    {SG.globals.preferences.using_user_invite_link &&
                        <ForgotLoginPasswordButton
                            loginState={SCREEN_RESET_PASSWORD}
                            setLoginState={this.props.setLoginState}
                        />}
                </LoginFormFooter>
            </form>
        );
    }
}

PasswordLoginForm.propTypes = {
    setLoginState: PropTypes.func.isRequired,
};

export default PasswordLoginForm;
