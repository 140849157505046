import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormFooterLink from './LoginFormFooterLink';
import { bindCache } from 'sg/util/react_helpers';

class ForgotLoginPasswordButton extends PureComponent {
    constructor(props) {
        super(props);
        this.bind = bindCache(this);
    }

    handleClick() {
        this.props.setLoginState({
            screenState: this.props.loginState,
        });
    }

    render() {
        return (
            <LoginFormFooterLink onClick={this.bind(this.handleClick)}>
                {i18next.t('components.login.forgot_login_password')}
            </LoginFormFooterLink>
        );
    }
}

ForgotLoginPasswordButton.propTypes = {
    loginState: PropTypes.string.isRequired,
    setLoginState: PropTypes.func.isRequired,
};

export default ForgotLoginPasswordButton;
