import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, SvgIcon } from '@sgds/react';

import withDesignLanguageContext from './withDesignLanguageContext';
import { memoize } from 'sg/util/react_helpers';

const iconVariants = {
    notice: 'COMPLETE_16',
    warning: 'ISSUE_16',
    error: 'ISSUE_16',
};

const iconColorVariants = {
    notice: 'COLOR_FEEDBACK_POSITIVE',
    warning: 'COLOR_FEEDBACK_WARNING',
    error: 'COLOR_INPUT_INVALID',
};

function sanitizeHTML(markup) {
    const d = document.createElement('div');
    d.innerHTML = markup;
    // only anticipating paragraph tags
    // @NOTE RV and SG Desktop's browser (Qt4) does not return a native array.
    Array.prototype.forEach.call(d.querySelectorAll(':not(p)'), elem => elem.parentNode.removeChild(elem));
    return d.innerHTML;
}

class FlashMessage extends PureComponent {
    render() {
        const { variant, designLanguageContext, children } = this.props;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SvgIcon
                    iconId={designLanguageContext.basicsValues.icon[iconVariants[variant]]}
                    color={designLanguageContext.roleValues[iconColorVariants[variant]]}
                />
                <Text
                    style={{
                        marginLeft: designLanguageContext.basicsValues.spacing.X_SMALL,
                        flexShrink: 10000,
                    }}
                >
                    <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(children) }} />
                </Text>
            </div>
        );
    }
}

FlashMessage.propTypes = {
    variant: PropTypes.oneOf(['notice', 'warning', 'error']),
    children: PropTypes.string.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object.isRequired,
        roleValues: PropTypes.object.isRequired,
    }).isRequired,
};

export default withDesignLanguageContext(FlashMessage);
