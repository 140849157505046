// this is a fork of the stylesheet from:
// https://git.autodesk.com/shotgun/sgds/blob/master/packages/component-field-text/src/styleSheet.js
// and we should move back to that as soon as possible.

import { toEm, transformCssLength } from '@sgds/react';

export default function getLoginFormInputStyleSheet(forceIsExpanded) {
    return ({ basicsValues, roleValues }) => ({
        hasFocus,
        hasValue,
        isDisabled,
        isExpanded,
        isInvalid,
        isReadOnly,
    }) => {
        if (forceIsExpanded) {
            isExpanded = true;
        }

        const inputHeight = toEm(basicsValues.sizing.MEDIUM);

        const cursor = isReadOnly || isDisabled ? 'default' : 'text';

        return {
            container: {
                minWidth: toEm(roleValues.WIDTH_FIELD_MIN),
                maxWidth: toEm(roleValues.WIDTH_FIELD_MAX),

                ...(isDisabled && {
                    opacity: roleValues.OPACITY_INPUT_DISABLED,
                }),
            },

            labelContainer: {
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
                alignContent: 'flex-end',

                height: transformCssLength(inputHeight, x => x * 2),
            },

            labelText: {
                display: 'block',
                position: 'absolute',

                ...(isExpanded
                    ? {
                          bottom: inputHeight,
                          paddingBottom: toEm(basicsValues.spacing.SMALL),
                      }
                    : {
                          bottom: 0,
                          // NOTE: no `toEm` for padding: needs to offset border
                          paddingBottom: transformCssLength(roleValues.BORDER_WIDTH_INPUT, x => x * 2),
                      }),

                ...(hasValue && {
                    color: roleValues.COLOR_TEXT_FIELD_LABEL_FILLED,
                }),

                ...(hasFocus && {
                    color: roleValues.COLOR_TEXT_FIELD_LABEL_ACTIVE,
                }),

                ...(isExpanded
                    ? {
                          fontSize: toEm(roleValues.FONT_SIZE_FIELD_LABEL_ACTIVE),
                          lineHeight: roleValues.LINE_HEIGHT_FIELD_LABEL_ACTIVE,
                      }
                    : {
                          fontSize: toEm(roleValues.FONT_SIZE_FIELD_LABEL),
                          // Ensure the label fills the entire height of the wrapper so there
                          // are no areas the user can click on that fail to focus the input:
                          lineHeight: inputHeight,

                          cursor,
                      }),
            },

            input: {
                flex: 1,

                ...(!isExpanded && {
                    cursor,
                }),
            },

            instructions: {
                fontSize: toEm(roleValues.FONT_SIZE_FIELD_INSTRUCTIONS),
                color: roleValues.COLOR_TEXT_FIELD_INSTRUCTIONS,
                lineHeight: roleValues.LINE_HEIGHT_FIELD_INSTRUCTIONS,

                ...(isInvalid && {
                    color: roleValues.COLOR_FEEDBACK_ALERT,
                }),
            },

            requiredAnnotation: {
                fontSize: toEm(roleValues.FONT_SIZE_FIELD_REQUIRED_ANNOTATION),
                color: roleValues.COLOR_TEXT_FIELD_REQUIRED_ANNOTATION,
                lineHeight: roleValues.LINE_HEIGHT_FIELD_REQUIRED_ANNOTATION,
            },
        };
    };
}
