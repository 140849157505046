import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withDesignLanguageContext from './withDesignLanguageContext';

import { memoize } from 'sg/util/react_helpers';

const dividerStyle = memoize(marginHorizontal => ({
    marginLeft: marginHorizontal,
    marginRight: marginHorizontal,
    fontSize: '0.7em',
    fontWeight: 100,
}));

class LoginFormFooterLinkDivider extends PureComponent {
    render() {
        const { basicsValues } = this.props.designLanguageContext;
        return <span style={dividerStyle(basicsValues.spacing.X_SMALL)}>|</span>;
    }
}

LoginFormFooterLinkDivider.propTypes = {
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(LoginFormFooterLinkDivider);
