import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sgds/react';

import LoginFormText from './LoginFormText';
import LoginFormButton from './LoginFormButton';

class SamlLoginForm extends PureComponent {
    render() {
        return (
            <form action="/user/login" method="post" onSubmit={this.props.onSubmit}>
                <LoginFormText>
                    {i18next.t('components.login.saml_login_message')}
                </LoginFormText>
                <LoginFormButton name="commit" type="submit">
                    {i18next.t('components.login.saml_sign_in_button')}
                </LoginFormButton>
                <input type="hidden" name="ignore_browser_check" defaultValue="1" />
                <input type="hidden" name="saml_login" defaultValue="1" />
            </form>
        );
    }
}

export default SamlLoginForm;
