import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormText from './LoginFormText';
import LoginFormInput from './LoginFormInput';
import LoginFormButton from './LoginFormButton';
import LoginFormFooterLink from './LoginFormFooterLink';
import LoginFormFooter from './LoginFormFooter';
import LoginFormFooterLinkDivider from './LoginFormFooterLinkDivider';
import ForgotLoginPasswordButton from './ForgotLoginPasswordButton';

import { SCREEN_RESET_PASSWORD } from './ScreenState';

class SamlAccountLinkPasswordForm extends PureComponent {
    render() {
        const { samlEmail, samlLogin, oldLogin, onSubmit, setLoginState } = this.props;
        const loginFieldDisabled = Boolean(oldLogin);
        return (
            <form action="/saml/link_account" method="post" onSubmit={onSubmit}>
                <LoginFormText>
                    {i18next.t('components.login.password_text')}
                </LoginFormText>
                <LoginFormInput
                    label={i18next.t('components.login.login_label')}
                    id="user_login"
                    name="old_login"
                    isDisabled={loginFieldDisabled}
                    defaultValue={oldLogin}
                />
                {loginFieldDisabled && <input type="hidden" name="old_login" value={oldLogin} />}
                <LoginFormInput
                    label={i18next.t('components.login.password_label')}
                    id="user_password"
                    name="old_password"
                    type="password"
                />
                <LoginFormButton name="commit" type="submit">
                    {i18next.t('components.login.link_account_button')}
                </LoginFormButton>
                <input type="hidden" name="ignore_browser_check" defaultValue="1" />
                <input type="hidden" name="saml_email" defaultValue={samlEmail} />
                <input type="hidden" name="saml_login" defaultValue={samlLogin} />
                <LoginFormFooter>
                    {SG.globals.preferences.using_user_invite_link &&
                        <Fragment>
                            <ForgotLoginPasswordButton
                                loginState={SCREEN_RESET_PASSWORD}
                                setLoginState={setLoginState}
                            />
                            <LoginFormFooterLinkDivider />
                        </Fragment>}
                    <LoginFormFooterLink href={`/saml/saml_linking_skip${window.location.search}`}>
                        {i18next.t('components.login.no_account')}
                    </LoginFormFooterLink>
                </LoginFormFooter>
            </form>
        );
    }
}

SamlAccountLinkPasswordForm.propTypes = {
    samlEmail: PropTypes.string.isRequired,
    samlLogin: PropTypes.string.isRequired,
    oldLogin: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    setLoginState: PropTypes.func.isRequired,
};

export default SamlAccountLinkPasswordForm;
