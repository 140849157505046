import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormText from './LoginFormText';
import LoginFormButton from './LoginFormButton';

class AutodeskIdentityLoginForm extends PureComponent {
    render() {
        const { email, oldLogin } = this.props;
        return (
            <form action="/autodesk_identity/init_authentication" method="get">
                <LoginFormText>
                    {i18next.t('components.login.autodesk_identity_login_message')}
                </LoginFormText>
                <LoginFormButton name="commit" type="submit" onClick={() => {}}>
                    {i18next.t('components.login.autodesk_identity_sign_in_button')}
                </LoginFormButton>
                <input type="hidden" name="email" defaultValue={email} />
                <input type="hidden" name="old_login" defaultValue={oldLogin} />
            </form>
        );
    }
}

AutodeskIdentityLoginForm.propTypes = {
    email: PropTypes.string,
    oldLogin: PropTypes.string,
};

export default AutodeskIdentityLoginForm;
