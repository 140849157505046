// TODO: As roles are added to the design language, they need to be removed from here. Otherwise, the old overrides
// in here will always be used.

// Using require here as code gen scripts in node use this as well.
const Color = require('color');
const { default: designLanguage } = require('@sgds/design-language');

function higToRgb(color, isGray = false) {
    const colorToConvert = Color(color);
    return isGray ? colorToConvert.desaturate(1).rgb().toString() : colorToConvert.toString();
}

function mix(color1, color2, weight) {
    return Color(color1).mix(Color(color2), weight);
}

// NOTE: wrapping in function with context signature so that `@sgds/utils-codemods` can do it's thang.
const makeDefaultTheme = ({ basicsValues, roleValues: defaultThemeRoleValues }) => {
    const DEFAULT_THEME_SURFACE_LEVELS = {
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_1': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_2': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_2_5': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_3': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },

        'roleValues.SURFACE_LEVEL_1': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.SURFACE_LEVEL_2': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.SURFACE_LEVEL_2_5': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.SURFACE_LEVEL_3': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
    };

    const DEFAULT_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR = mix(
        basicsValues.color.BLUE_050,
        defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        0.92
    );

    const DEFAULT_THEME_GRID_CELL_HOVER_BACKGROUND_COLOR = mix(
        // TODO: This colour does not exist in current SGDS, replace once it exists
        '#808080',
        defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        0.92
    );

    return {
        // ------------------------------------------------------------
        // Surface Level Styles
        // ------------------------------------------------------------
        ...DEFAULT_THEME_SURFACE_LEVELS,

        // ------------------------------------------------------------
        // Root Styles
        // ------------------------------------------------------------
        'roleValues.TRANSPARENT_BACKGROUND': { value: 'transparent' },
        'roleValues.BODY_FONT_SIZE': { value: basicsValues.fontSize.X_SMALL },
        'roleValues.BODY_FONT_WEIGHT': { value: basicsValues.fontWeight.REGULAR },
        'roleValues.BODY_LINE_HEIGHT': { value: basicsValues.lineHeight.TIGHT },
        'roleValues.BODY_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color },
        // This came from design for SG-9603
        // https://jira.autodesk.com/browse/SG-9603?focusedCommentId=4889286&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-4889286
        'roleValues.FONT_WEIGHT_BOLDER': { value: 900 },
        'roleValues.PRIMARY_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.SUBTLE_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_SUBTLE },
        'roleValues.DISABLED_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_DISABLED },
        // TODO: SELECTION_COLOR: blackThemeRoleValues.COLOR_SELECTION,
        'roleValues.SELECTION_COLOR': { value: defaultThemeRoleValues.COLOR_SELECTION },
        'roleValues.ACCENT_COLOR': { value: defaultThemeRoleValues.COLOR_ACCENT },
        'roleValues.CONTRAST_COLOR': { value: defaultThemeRoleValues.COLOR_ACCENT_CONTRAST },
        'roleValues.BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.BLACK}, 0.1)`,
        },
        'roleValues.BORDER_DASHED': {
            value: `${basicsValues.borderWidth.HAIRLINE} dashed rgba(${basicsValues.color.BLACK}, 0.1)`,
        },
        'roleValues.BORDER_COLOR': { value: `rgba(${basicsValues.color.BLACK}, 0.1)` },
        'roleValues.BORDER_TRANSPARENT': { value: `${basicsValues.borderWidth.HAIRLINE} solid transparent` },
        'roleValues.BACKGROUND_DIVIDER_TRANSPARENT_10_COLOR': { value: `rgba(${basicsValues.color.BLACK}, 0.1)` },
        'roleValues.BACKGROUND_DIVIDER_TRANSPARENT_25_COLOR': { value: `rgba(${basicsValues.color.BLACK}, 0.25)` },
        'roleValues.BACKGROUND_DIVIDER_SOLID_COLOR': { value: basicsValues.color.GRAY_030 },
        'roleValues.DISABLED_OPACITY': { value: defaultThemeRoleValues.OPACITY_DISABLED },
        'roleValues.TITLE_FONT_SIZE': { value: basicsValues.fontSize.LARGE },
        'roleValues.SUB_TITLE_FONT_SIZE': { value: basicsValues.fontSize.SMALL },
        'roleValues.PAGE_TITLE_FONT_SIZE': { value: basicsValues.fontSize.LARGE },
        'roleValues.WEBKIT_SCROLLBACK_THUMB_BACKGROUND_COLOR': { value: `rgba(${basicsValues.color.BLACK}, 0.2)` },
        'roleValues.CONTAINER_BORDER_RADIUS': { value: '2px' },
        'roleValues.CONTAINER_BORDER_RADIUS_LARGE': { value: '0.5rem' },
        'roleValues.CONTAINER_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.CONTAINER_SECONDARY_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.CONTAINER_BOX_SHADOW': {
            value: `(0 0 0 1px ${defaultThemeRoleValues.COLOR_BORDER_CONTAINER}, ${basicsValues.shadow
                .DROP} ${defaultThemeRoleValues.COLOR_SHADOW})`,
        },
        'roleValues.CONTAINER_SELECTED_BOX_SHADOW': {
            value: `(0 0 0 2px ${defaultThemeRoleValues.COLOR_SELECTION}, ${basicsValues.shadow
                .DROP} ${defaultThemeRoleValues.COLOR_SHADOW})`,
        },
        'roleValues.CONTAINER_WARNING_BACKGROUND_COLOR': { value: basicsValues.color.GOLD_020 },
        'roleValues.CONTAINER_ALERT_BACKGROUND_COLOR': { value: basicsValues.color.SALMON_020 },
        'roleValues.CONTAINER_POSITIVE_BACKGROUND_COLOR': { value: basicsValues.color.GREEN_020 },
        'roleValues.DROP_ZONE_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.PANEL_DIVIDER_BACKGROUND': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.RESIZABLE_PROXY_BORDER': { value: `2px solid ${basicsValues.color.GRAY_030}` },
        'roleValues.TEXT_SHADOW': { value: `0 1px 5px ${defaultThemeRoleValues.COLOR_BORDER_CONTAINER}` },

        // NOTE: we're not using SGDS's sgds_roles_COLOR_BACKGROUND_MODAL_MASK
        // which changes color with theme.
        // TODO: revert to SGDS roles one it's stable
        'roleValues.OVERLAY_MASK_BACKGROUND_COLOR': { value: `rgba(${basicsValues.color.BLACK}, 0.5)` },

        // ------------------------------------------------------------
        // Page Styles
        // ------------------------------------------------------------
        'roleValues.PAGE_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.PAGE_HEADER_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.DETAIL_PAGE_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.SIDE_BAR_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },

        // ------------------------------------------------------------
        // Grid Styles
        // ------------------------------------------------------------
        'roleValues.GRID_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color },
        'roleValues.GRID_HEADER_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.GRID_TABLE_HEADER_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.GRID_FOOTER_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.GRID_HEADING_SORTING_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_020 },
        'roleValues.GRID_CHECKBOX_COLUMN_WIDTH': { value: '1.25rem' },
        'roleValues.GRID_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid ${basicsValues.color.GRAY_020}`,
        },
        'roleValues.GRID_HEADER_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid ${basicsValues.color.GRAY_030}`,
        },
        'roleValues.GRID_VERTICAL_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.GRAY_020}, .4)`,
        },
        'roleValues.GRID_BORDER_COLOR': { value: basicsValues.color.GRAY_020 },
        'roleValues.GRID_RESIZE_PROXY_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.GRID_SUMMARY_TABLE_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.GRID_ROW_SELECTOR_BACKGROUND_COLOR': { value: 'inherit' },
        'roleValues.GRID_ROW_ENTITY_ICON_BACKGROUND_COLOR': { value: 'inherit' },
        'roleValues.GRID_ROW_ACTIVE_BACKGROUND_COLOR': { value: DEFAULT_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR },
        'roleValues.GRID_CELL_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.GRID_CELL_HOVER_BACKGROUND_COLOR': { value: DEFAULT_THEME_GRID_CELL_HOVER_BACKGROUND_COLOR },
        'roleValues.GRID_CELL_ACTIVE_BACKGROUND_COLOR': { value: DEFAULT_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR },
        'roleValues.GRID_CELL_ACTIVE_BORDER_COLOR': { value: basicsValues.color.GRAY_020 },
        'roleValues.GRID_CELL_ERROR_BACKGROUND_COLOR': { value: basicsValues.color.RED },
        'roleValues.GRID_CELL_ERROR_HOVER_BACKGROUND_COLOR': { value: basicsValues.color.SALMON_040 },
        'roleValues.GRID_CELL_PENDING_BACKGROUND_COLOR': { value: basicsValues.color.GREEN_050 },
        'roleValues.GRID_CELL_DISABLED_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.GRID_CELL_EDIT_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.GRID_CELL_LINE_HEIGHT': { value: basicsValues.lineHeight.TIGHT },
        'roleValues.GRID_CELL_PADDING_LEFT': { value: '16px' },
        'roleValues.GRID_CELL_ERROR_STATE_PADDING_LEFT': { value: '37px' }, // +16px icon width, +5px spacing
        'roleValues.MINI_GRID_CELL_CONTENT_BORDER_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.THUMBNAIL_GRID_CELL_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.THUMBNAIL_GRID_CELL_FIELDS_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },

        // ------------------------------------------------------------
        // Canvas Styles
        // ------------------------------------------------------------
        'roleValues.CANVAS_WIDGET_DRAG_HANDLE_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.CANVAS_HEADER_HEIGHT': { value: '28px' },

        'roleValues.CANVAS_WIDGET_DRAG_HANDLE_COLOR': { value: `rgba(${basicsValues.color.GRAY_064}, 0.6)` },
        'roleValues.CANVAS_WIDGET_SAVE_BUTTON_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.GRAY_064}, 0.3)`,
        },

        // ------------------------------------------------------------
        // Cell Styles
        // ------------------------------------------------------------
        'roleValues.CELL_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid ${basicsValues.color.GRAY_020}`,
        },

        // ------------------------------------------------------------
        // Navigation Styles
        // ------------------------------------------------------------
        'roleValues.NAV_ITEM_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.NAV_ITEM_HOVER_BACKGROUND_COLOR': { value: 'transparent' },
        'roleValues.NAV_ITEM_HOVER_COLOR': { value: defaultThemeRoleValues.COLOR_ACCENT },
        'roleValues.NAV_ITEM_ACTIVE_BACKGROUND_COLOR': { value: 'transparent' },
        'roleValues.NAV_ITEM_ACTIVE_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.NAV_ITEM_ACTIVE_FONT_WEIGHT': { value: basicsValues.fontWeight.BOLD },
        'roleValues.NAV_ITEM_SELECTED_BORDER_BOTTOM': { value: `3px solid ${defaultThemeRoleValues.COLOR_ACCENT}` },
        'roleValues.NAV_ITEM_SELECTED_PADDING_TOP': { value: '6px' },
        'roleValues.NAV_ITEM_SELECTED_PADDING_BOTTOM': { value: '3px' },

        // ------------------------------------------------------------
        // Project Navigation Configuration Styles
        // ------------------------------------------------------------
        'roleValues.PROJECT_CONFIG_NAV_TITLE_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.PROJECT_CONFIG_NAV_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.PROJECT_CONFIG_NAV_ITEM_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.PROJECT_CONFIG_NAV_ITEM_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.GRAY_050}, 0.3)`,
        },

        // ------------------------------------------------------------
        // Global Navigation Styles
        // ------------------------------------------------------------
        'roleValues.GLOBAL_NAV_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.GLOBAL_NAV_BANNER_PRIMARY_BACKGROUND_COLOR': {
            value: basicsValues.color.BLUE_020,
        },

        // ------------------------------------------------------------
        // Project Navigation Styles
        // ------------------------------------------------------------
        'roleValues.PROJECT_NAV_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color },

        // ------------------------------------------------------------
        // Menu Styles
        // ------------------------------------------------------------
        'roleValues.MENU_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.MENU_LOADING_OVERLAY_BACKGROUND_COLOR': {
            value: `rgba(${defaultThemeRoleValues.colorScheme.surfaceLevel300Color}, 0.5)`,
        },
        'roleValues.MENU_SECTION_HEADING_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_MENU_SECTION_HEADING },
        'roleValues.MENU_ITEM_ACTIVE_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.COLOR_BACKGROUND_CONTAINER_SELECTION_HOVER,
        },
        'roleValues.MENU_ITEM_DISABLED_BACKGROUND_OPACITY': { value: defaultThemeRoleValues.OPACITY_DISABLED },
        'roleValues.MENU_ITEM_THUMBNAIL_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },

        // ------------------------------------------------------------
        // Thumbnail Styles
        // ------------------------------------------------------------
        'roleValues.THUMBNAIL_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },

        // ------------------------------------------------------------
        // Popover Styles
        // ------------------------------------------------------------
        'roleValues.POPOVER_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },

        // ------------------------------------------------------------
        // Filter Menu Styles
        // ------------------------------------------------------------
        'roleValues.FILTER_MENU_HEADER_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.FILTER_MENU_FOOTER_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.FILTER_MENU_SELECTED_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_020 },

        // ------------------------------------------------------------
        // Search Pop-up Styles
        // ------------------------------------------------------------
        'roleValues.SEARCH_POP_UP_HEADER_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },

        // ------------------------------------------------------------
        // Form Element Styles
        // ------------------------------------------------------------
        // TODO: once SGDS has HIG-compliant "flat" Button, these would
        // be removed in favor of straight 'sgds_roles_' equivalents.
        'roleValues.BUTTON_HOVER_BACKGROUND_COLOR': { value: basicsValues.color.BLACK },
        'roleValues.BUTTON_HOVER_HALO_COLOR': { value: `rgba(${basicsValues.color.GRAY_050}, .5)` },
        'roleValues.BUTTON_ACTIVE_HALO_COLOR': { value: `rgba(${basicsValues.color.BLUE_030}, .5)` },
        'roleValues.STANDARD_HEADER_HEIGHT': { value: '18px' },
        'roleValues.INPUT_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid ${defaultThemeRoleValues.COLOR_BORDER_INPUT}`,
        },
        'roleValues.INPUT_BORDER_COLOR': { value: defaultThemeRoleValues.COLOR_BORDER_INPUT },
        'roleValues.INPUT_BACKGROUND_COLOR': { value: `rgba(${basicsValues.color.WHITE}, 0.25)` },
        'roleValues.INPUT_BACKGROUND_COLOR_SOLID': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.INPUT_LABEL_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_FIELD_LABEL_FILLED },
        'roleValues.INPUT_PLACEHOLDER_COLOR': { value: defaultThemeRoleValues.COLOR_TEXT_DISABLED },
        'roleValues.PASSWORD_STRENGTH_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },

        // ------------------------------------------------------------
        // Project Gutter(padding) and Spacing(margin)
        // ------------------------------------------------------------
        'roleValues.GUTTER_X_SMALL': { value: '0.1875rem' },
        'roleValues.GUTTER_SMALL': { value: basicsValues.spacing.XX_SMALL },
        'roleValues.GUTTER_BASE': { value: basicsValues.spacing.X_SMALL },
        'roleValues.GUTTER_BASE_STRETCH': { value: basicsValues.spacing.SMALL },
        'roleValues.GUTTER_MEDIUM': { value: basicsValues.spacing.MEDIUM },
        'roleValues.GUTTER_LARGE': { value: basicsValues.spacing.LARGE },

        // margin
        'roleValues.MARGIN_XX_SMALL': { value: basicsValues.spacing.XX_SMALL },
        'roleValues.MARGIN_X_SMALL': { value: basicsValues.spacing.X_SMALL },
        'roleValues.MARGIN_SMALL': { value: basicsValues.spacing.SMALL },
        'roleValues.MARGIN_MEDIUM': { value: basicsValues.spacing.MEDIUM },
        'roleValues.MARGIN_LARGE': { value: basicsValues.spacing.LARGE },
        'roleValues.MARGIN_X_LARGE': { value: basicsValues.spacing.X_LARGE },

        // ------------------------------------------------------------
        // Project Overview Styles
        // ------------------------------------------------------------
        'roleValues.PROJECT_OVERVIEW_PAGE_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.PROJECT_OVERVIEW_BILLBOARD_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.PROJECT_OVERVIEW_LEFT_PANE_HEADER_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.PROJECT_OVERVIEW_PERSON_OVERFLOW_COUNT_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.PROJECT_OVERVIEW_EMPTY_BILLBOARD_COLOR': {
            value: `rgba(${basicsValues.color.WHITE}, .4)`,
        },

        // ------------------------------------------------------------
        // Activity Stream Styles
        // ------------------------------------------------------------
        'roleValues.NOTE_THREAD_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.NOTE_LINK_BRICK_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.NOTE_LINK_BRICK_HOVER_BACKGROUND_COLOR': {
            value: `rgba(${defaultThemeRoleValues.colorScheme.surfaceLevel200Color}, 0.7)`,
        },

        // ------------------------------------------------------------
        // Activity Stream Styles
        // ------------------------------------------------------------
        'roleValues.CANVAS_TABS_BACKGROUND_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color },

        // ------------------------------------------------------------
        // Master Details Styles
        // ------------------------------------------------------------
        'roleValues.MASTER_DETAILS_GROUP_LIST_HEADER_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.MASTER_DETAILS_ENTITY_BOX_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },

        // ------------------------------------------------------------
        // My Tasks Styles
        // ------------------------------------------------------------
        'roleValues.MY_TASKS_MINI_TASK_DETAILS_HEADER_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        //
        // Permissions Page
        // ------------------------------------------------------------
        'roleValues.PERMISSIONS_PAGE_ITEM_HOVER_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.PERMISSIONS_PAGE_CONTENT_OVERLAY': {
            value: `rgba(${defaultThemeRoleValues.colorScheme.surfaceLevel300Color}, 0.3)`,
        },
        'roleValues.UNSAVED_MODIFIED_ITEM_BACKGROUND': { value: `rgba(${basicsValues.color.YELLOW}, .1)` },

        // ------------------------------------------------------------
        // Site Preferences
        // ------------------------------------------------------------
        'roleValues.SITE_PREF_CONTENT_TABLE_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.SITE_PREF_GROUP_BACKGROUND': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.SITE_PREF_GROUP_PREFERENCES_BOX_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.SITE_PREF_FILE_UPLOAD_HEADER_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.SITE_PREF_FILE_UPLOAD_BODY_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        // ------------------------------------------------------------
        // User Account Settings
        // ------------------------------------------------------------
        'roleValues.USER_SETTINGS_2FA_CREDITCARD_BOX_FOOTER': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.USER_SETTINGS_2FA_CREDITCARD_BOX_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },

        // ------------------------------------------------------------
        // Invite Pages
        // TODO: deprecate in favor of general `PRIMARY|SECONDARY_BUTTON` roles once merged
        // ------------------------------------------------------------
        'roleValues.INVITE_PAGE_WIDTH': { value: '342px' },
        'roleValues.INVITE_PAGE_PRIMARY_BUTTON_COLOR': { value: defaultThemeRoleValues.COLOR_BUTTON },
        // TODo: Figure out a better SGDS role for INVITE_PAGE_PRIMARY_BUTTON_HOVER_COLOR
        'roleValues.INVITE_PAGE_PRIMARY_BUTTON_HOVER_COLOR': { value: basicsValues.color.BLUE_040 },
        'roleValues.INVITE_PAGE_SECONDARY_BUTTON_COLOR': { value: higToRgb(basicsValues.color.GRAY_050, true) },
        'roleValues.INVITE_PAGE_SECONDARY_BUTTON_HOVER_COLOR': { value: higToRgb(basicsValues.color.GRAY_040, true) },

        // Notes App
        // ------------------------------------------------------------
        'roleValues.NOTES_APP_PAGE_HEADER_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.NOTES_APP_WIDGET_NOTE_HEADER_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.NOTES_APP_WIDGET_NOTE_BODY_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.NOTES_APP_VERSION_BACKGROUND': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.NOTES_APP_SELECTED_VERSION_BACKGROUND': { value: DEFAULT_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR },
        'roleValues.NOTES_APP_DRAFT_ATTACHMENT_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.NOTES_APP_CANVAS_BACKGROUND': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.NOTES_APP_MODE_SWITCHER_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.NOTES_APP_MODE_PANEL_DIVIDER_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        // ------------------------------------------------------------
        // Work Schedule page styles
        // ------------------------------------------------------------
        'roleValues.WORK_SCHEDULE_COMMENT_BOX_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.WORK_SCHEDULE_ON_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.WORK_SCHEDULE_ON_SELECTED_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_020 },
        'roleValues.WORK_SCHEDULE_OFF_BACKGROUND_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.WORK_SCHEDULE_OFF_SELECTED_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_030 },
        'roleValues.WORK_SCHEDULE_CALENDAR_HEADER_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.WORK_SCHEDULE_CALENDAR_DAY_OFF_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.WORK_SCHEDULE_CALENDAR_DAY_ON_BACKGROUND': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.WORK_SCHEDULE_CALENDAR_DAY_TODAY_BACKGROUND': { value: basicsValues.color.YELLOW },
        'roleValues.WORK_SCHEDULE_CALENDAR_WEEK_SELECTED_BACKGROUND': { value: basicsValues.color.BLUE_030 },

        // ------------------------------------------------------------
        // Badge Styles
        // ------------------------------------------------------------
        'roleValues.BADGE_MODIFIED_DATA_BADGE_COLOR': { value: basicsValues.color.BLACK },
        'roleValues.BADGE_MODIFIED_DATA_BADGE_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_040 },

        // ---------÷---------------------------------------------------

        // NSX Panel Styles
        // ------------------------------------------------------------
        'roleValues.NSX_PANEL_BACKGROUND': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.NSX_PANEL_BOX_SHADOW': { value: `(0 0 20px ${defaultThemeRoleValues.COLOR_SHADOW})` },

        // Table Overlay Styles
        // ------------------------------------------------------------
        'roleValues.TABLE_OVERLAY_HEADER_BG_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.TABLE_OVERLAY_TOOLBAR_BG_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.TABLE_OVERLAY_BODY_BG_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color },
        'roleValues.TABLE_OVERLAY_FOOTER_BG_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color },

        // Table Styles
        // --------------------------------------------------
        'roleValues.TABLE_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.BLACK}, .1)`,
        },
        'roleValues.TABLE_HEADER_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.BLACK}, .25)`,
        },

        // Two Pane Styles
        // ------------------------------------------------------------
        'roleValues.TWO_PANE_VIEW_SCROLL_CONTAINER_BG_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.TWO_PANE_VIEW_NAVIGATION_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color },
        'roleValues.TWO_PANE_VIEW_NAVIGATION_TAB_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.TWO_PANE_VIEW_NAVIGATION_AWAY_HIGHLIGHT_BG_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel250Color,
        },

        // Pipeline Summary Styles
        // ------------------------------------------------------------
        'roleValues.PIPELINE_SUMMARY_BG_COLOR': { value: defaultThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.PIPELINE_SUMMARY_SELECTED_BG_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel300Color,
        },

        // Field Property Summary Styles
        // ------------------------------------------------------------
        'roleValues.FIELD_PROPERTIES_PANEL_BG_COLOR': {
            value: defaultThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        // Trial Banner
        'roleValues.TRIAL_BANNER_BUTTON_BORDER_COLOR': { value: basicsValues.color.COOL_GRAY_030 },
    };
};

const makeBlackTheme = ({ basicsValues, roleValues: blackThemeRoleValues }) => {
    const BLACK_THEME_SURFACE_LEVELS = {
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_1': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_2': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_2_5': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.COLOR_BACKGROUND_SURFACE_LEVEL_3': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },

        'roleValues.SURFACE_LEVEL_1': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.SURFACE_LEVEL_2': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.SURFACE_LEVEL_2_5': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.SURFACE_LEVEL_3': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
    };

    const DARK_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR = mix(
        '#38abdf',
        blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        0.7
    );

    const DARK_THEME_GRID_CELL_HOVER_BACKGROUND_COLOR = mix(
        // TODO: This colour does not exist in current SGDS, replace once it exists
        '#1f1f1f',
        blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        0.7
    );

    return {
        // ------------------------------------------------------------
        // Surface Level Styles
        // ------------------------------------------------------------
        ...BLACK_THEME_SURFACE_LEVELS,

        // ------------------------------------------------------------
        // Root Styles
        // ------------------------------------------------------------
        'roleValues.BODY_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel300Color },
        'roleValues.PRIMARY_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.SUBTLE_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_SUBTLE },
        'roleValues.DISABLED_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_DISABLED },
        'roleValues.ACCENT_COLOR': { value: blackThemeRoleValues.COLOR_ACCENT },
        'roleValues.CONTRAST_COLOR': { value: blackThemeRoleValues.COLOR_ACCENT_CONTRAST },
        'roleValues.BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.WHITE}, 0.1)`,
        },
        'roleValues.BORDER_DASHED': {
            value: `${basicsValues.borderWidth.HAIRLINE} dashed rgba(${basicsValues.color.WHITE}, 0.1)`,
        },
        'roleValues.BORDER_COLOR': { value: `rgba(${basicsValues.color.WHITE}, 0.1)` },
        'roleValues.BACKGROUND_DIVIDER_TRANSPARENT_10_COLOR': { value: `rgba(${basicsValues.color.WHITE}, 0.1)` },
        'roleValues.BACKGROUND_DIVIDER_TRANSPARENT_25_COLOR': { value: `rgba(${basicsValues.color.WHITE}, 0.25)` },
        'roleValues.BACKGROUND_DIVIDER_SOLID_COLOR': { value: basicsValues.color.GRAY_062 },
        'roleValues.DISABLED_OPACITY': { value: blackThemeRoleValues.OPACITY_DISABLED },
        'roleValues.SELECTION_COLOR': { value: blackThemeRoleValues.COLOR_SELECTION },
        'roleValues.WEBKIT_SCROLLBACK_THUMB_BACKGROUND_COLOR': { value: `rgba(${basicsValues.color.WHITE}, 0.3)` },
        'roleValues.CONTAINER_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.CONTAINER_SECONDARY_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.CONTAINER_BOX_SHADOW': {
            value: `(0 0 0 1px ${blackThemeRoleValues.COLOR_BORDER_CONTAINER}, ${basicsValues.shadow
                .DROP} ${blackThemeRoleValues.COLOR_SHADOW})`,
        },
        'roleValues.CONTAINER_SELECTED_BOX_SHADOW': {
            value: `(0 0 0 2px ${blackThemeRoleValues.COLOR_SELECTION}, ${basicsValues.shadow
                .DROP} ${blackThemeRoleValues.COLOR_SHADOW})`,
        },
        'roleValues.CONTAINER_WARNING_BACKGROUND_COLOR': { value: basicsValues.color.GOLD_070 },
        'roleValues.CONTAINER_ALERT_BACKGROUND_COLOR': { value: basicsValues.color.SALMON_070 },
        'roleValues.CONTAINER_POSITIVE_BACKGROUND_COLOR': { value: basicsValues.color.GREEN_070 },
        'roleValues.DROP_ZONE_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.PANEL_DIVIDER_BACKGROUND': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.RESIZABLE_PROXY_BORDER': { value: `2px solid ${basicsValues.color.GRAY_062}` },
        'roleValues.TEXT_SHADOW': { value: `0 1px 5px ${blackThemeRoleValues.COLOR_BORDER_CONTAINER}` },

        // ------------------------------------------------------------
        // Page Styles
        // ------------------------------------------------------------
        'roleValues.PAGE_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.PAGE_HEADER_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.DETAIL_PAGE_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.SIDE_BAR_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },

        // ------------------------------------------------------------
        // Grid Styles
        // ------------------------------------------------------------
        'roleValues.GRID_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel300Color },
        'roleValues.GRID_HEADER_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.GRID_TABLE_HEADER_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.GRID_FOOTER_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.GRID_HEADING_SORTING_BACKGROUND_COLOR': { value: `rgba(${basicsValues.color.WHITE}, .4)` },
        'roleValues.GRID_CHECKBOX_COLUMN_WIDTH': { value: '1.25rem' },
        'roleValues.GRID_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid ${basicsValues.color.GRAY_050}`,
        },
        'roleValues.GRID_HEADER_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid ${basicsValues.color.GRAY_040}`,
        },
        'roleValues.GRID_VERTICAL_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.GRAY_050}, .4)`,
        },
        'roleValues.GRID_BORDER_COLOR': { value: basicsValues.color.GRAY_050 },
        'roleValues.GRID_RESIZE_PROXY_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.GRID_SUMMARY_TABLE_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.GRID_ROW_ACTIVE_BACKGROUND_COLOR': { value: DARK_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR },
        'roleValues.GRID_CELL_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.GRID_CELL_HOVER_BACKGROUND_COLOR': { value: DARK_THEME_GRID_CELL_HOVER_BACKGROUND_COLOR },
        'roleValues.GRID_CELL_ACTIVE_BACKGROUND_COLOR': { value: DARK_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR },
        'roleValues.GRID_CELL_ACTIVE_BORDER_COLOR': { value: basicsValues.color.GRAY_050 },
        'roleValues.GRID_CELL_DISABLED_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.GRID_CELL_EDIT_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.MINI_GRID_CELL_CONTENT_BORDER_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.THUMBNAIL_GRID_CELL_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.THUMBNAIL_GRID_CELL_FIELDS_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },

        // ------------------------------------------------------------
        // Canvas Styles
        // ------------------------------------------------------------
        'roleValues.CANVAS_WIDGET_DRAG_HANDLE_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.CANVAS_WIDGET_DRAG_HANDLE_COLOR': { value: `rgba(${basicsValues.color.GRAY_023}, 0.6)` },
        'roleValues.CANVAS_WIDGET_SAVE_BUTTON_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.GRAY_064}, 0.3)`,
        },

        // ------------------------------------------------------------
        // Navigation Styles
        // ------------------------------------------------------------
        'roleValues.NAV_ITEM_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.NAV_ITEM_HOVER_COLOR': { value: blackThemeRoleValues.COLOR_ACCENT },
        'roleValues.NAV_ITEM_ACTIVE_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.NAV_ITEM_SELECTED_BORDER_BOTTOM': { value: `3px solid ${blackThemeRoleValues.COLOR_ACCENT}` },

        // ------------------------------------------------------------
        // Project Navigation Configuration Styles
        // ------------------------------------------------------------
        'roleValues.PROJECT_CONFIG_NAV_TITLE_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_PRIMARY },
        'roleValues.PROJECT_CONFIG_NAV_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.PROJECT_CONFIG_NAV_ITEM_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.PROJECT_CONFIG_NAV_ITEM_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.GRAY_050}, 0.3)`,
        },

        // ------------------------------------------------------------
        // Global Navigation Styles
        // ------------------------------------------------------------
        'roleValues.GLOBAL_NAV_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.GLOBAL_NAV_BANNER_PRIMARY_BACKGROUND_COLOR': {
            value: basicsValues.colors.autodeskBlue800,
        },

        // ------------------------------------------------------------
        // Project Navigation Styles
        // ------------------------------------------------------------
        'roleValues.PROJECT_NAV_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel250Color },

        // ------------------------------------------------------------
        // Menu Styles
        // ------------------------------------------------------------
        'roleValues.MENU_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.MENU_LOADING_OVERLAY_BACKGROUND_COLOR': {
            value: `rgba(${blackThemeRoleValues.colorScheme.surfaceLevel300Color}, 0.5)`,
        },
        'roleValues.MENU_SECTION_HEADING_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_MENU_SECTION_HEADING },
        'roleValues.MENU_ITEM_ACTIVE_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.COLOR_BACKGROUND_CONTAINER_SELECTION_HOVER,
        },
        'roleValues.MENU_ITEM_DISABLED_BACKGROUND_OPACITY': { value: blackThemeRoleValues.OPACITY_DISABLED },
        'roleValues.MENU_ITEM_THUMBNAIL_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },

        // ------------------------------------------------------------
        // Thumbnail Styles
        // ------------------------------------------------------------
        'roleValues.THUMBNAIL_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },

        // ------------------------------------------------------------
        // Popover Styles
        // ------------------------------------------------------------
        'roleValues.POPOVER_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },

        // ------------------------------------------------------------
        // Filter Menu Styles
        // ------------------------------------------------------------
        'roleValues.FILTER_MENU_HEADER_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.FILTER_MENU_FOOTER_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.FILTER_MENU_SELECTED_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_060 },

        // ------------------------------------------------------------
        // Search Pop-up Styles
        // ------------------------------------------------------------
        'roleValues.SEARCH_POP_UP_HEADER_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },

        // ------------------------------------------------------------
        // Form Element Styles
        // ------------------------------------------------------------
        'roleValues.BUTTON_HOVER_BACKGROUND_COLOR': { value: basicsValues.color.WHITE },
        'roleValues.BUTTON_ACTIVE_HALO_COLOR': { value: `rgba(${blackThemeRoleValues.COLOR_ACCENT}, .5)` },
        'roleValues.INPUT_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid ${blackThemeRoleValues.COLOR_BORDER_INPUT}`,
        },
        'roleValues.INPUT_BORDER_COLOR': { value: blackThemeRoleValues.COLOR_BORDER_INPUT },
        'roleValues.INPUT_BACKGROUND_COLOR': { value: `rgba(${basicsValues.color.BLACK}, 0.25)` },
        'roleValues.INPUT_BACKGROUND_COLOR_SOLID': { value: blackThemeRoleValues.colorScheme.surfaceLevel250Color },
        'roleValues.INPUT_LABEL_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_FIELD_LABEL_FILLED },
        'roleValues.INPUT_PLACEHOLDER_COLOR': { value: blackThemeRoleValues.COLOR_TEXT_DISABLED },
        'roleValues.PASSWORD_STRENGTH_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },

        // ------------------------------------------------------------
        // Project Overview Styles
        // ------------------------------------------------------------
        'roleValues.PROJECT_OVERVIEW_PAGE_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.PROJECT_OVERVIEW_BILLBOARD_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.PROJECT_OVERVIEW_LEFT_PANE_HEADER_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.PROJECT_OVERVIEW_PERSON_OVERFLOW_COUNT_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.PROJECT_OVERVIEW_EMPTY_BILLBOARD_COLOR': { value: `rgba(${basicsValues.color.WHITE}, .15)` },

        // ------------------------------------------------------------
        // Activity Stream Styles
        // ------------------------------------------------------------
        'roleValues.NOTE_THREAD_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.NOTE_LINK_BRICK_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.NOTE_LINK_BRICK_HOVER_BACKGROUND_COLOR': {
            value: `rgba(${blackThemeRoleValues.colorScheme.surfaceLevel200Color}, 0.7)`,
        },
        // ------------------------------------------------------------
        // Activity Stream Styles
        // ------------------------------------------------------------
        'roleValues.CANVAS_TABS_BACKGROUND_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel250Color },

        // ------------------------------------------------------------
        // Master Details Styles
        // ------------------------------------------------------------
        'roleValues.MASTER_DETAILS_GROUP_LIST_HEADER_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.MASTER_DETAILS_ENTITY_BOX_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },

        // ------------------------------------------------------------
        // My Tasks Styles
        // ------------------------------------------------------------
        'roleValues.MY_TASKS_MINI_TASK_DETAILS_HEADER_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        // ------------------------------------------------------------
        // Permissions Page
        // ------------------------------------------------------------
        'roleValues.PERMISSIONS_PAGE_ITEM_HOVER_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.PERMISSIONS_PAGE_CONTENT_OVERLAY': {
            value: `rgba(${blackThemeRoleValues.colorScheme.surfaceLevel300Color}, 0.3)`,
        },
        'roleValues.UNSAVED_MODIFIED_ITEM_BACKGROUND': { value: `rgba(${basicsValues.color.WHITE}, .2)` },

        // ------------------------------------------------------------
        // Site Preferences
        // ------------------------------------------------------------
        'roleValues.SITE_PREF_CONTENT_TABLE_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.SITE_PREF_GROUP_BACKGROUND': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.SITE_PREF_GROUP_PREFERENCES_BOX_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.SITE_PREF_FILE_UPLOAD_HEADER_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.SITE_PREF_FILE_UPLOAD_BODY_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        // ------------------------------------------------------------
        // User Account Settings
        // ------------------------------------------------------------
        'roleValues.USER_SETTINGS_2FA_CREDITCARD_BOX_FOOTER': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.USER_SETTINGS_2FA_CREDITCARD_BOX_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        // ------------------------------------------------------------
        // Invite Pages
        // ------------------------------------------------------------
        'roleValues.INVITE_PAGE_PRIMARY_BUTTON_COLOR': { value: blackThemeRoleValues.COLOR_BUTTON },
        // TODo: Figure out a better SGDS role for INVITE_PAGE_PRIMARY_BUTTON_HOVER_COLOR
        'roleValues.INVITE_PAGE_PRIMARY_BUTTON_HOVER_COLOR': { value: basicsValues.color.BLUE_040 },
        'roleValues.INVITE_PAGE_SECONDARY_BUTTON_COLOR': { value: higToRgb(basicsValues.color.GRAY_050, true) },
        'roleValues.INVITE_PAGE_SECONDARY_BUTTON_HOVER_COLOR': { value: higToRgb(basicsValues.color.GRAY_040, true) },

        // Notes App
        // ------------------------------------------------------------
        'roleValues.NOTES_APP_PAGE_HEADER_BACKGROUND': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.NOTES_APP_WIDGET_NOTE_HEADER_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.NOTES_APP_WIDGET_NOTE_BODY_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.NOTES_APP_VERSION_BACKGROUND': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.NOTES_APP_SELECTED_VERSION_BACKGROUND': { value: DARK_THEME_GRID_CELL_ACTIVE_BACKGROUND_COLOR },
        'roleValues.NOTES_APP_DRAFT_ATTACHMENT_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.NOTES_APP_CANVAS_BACKGROUND': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.NOTES_APP_MODE_SWITCHER_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.NOTES_APP_MODE_PANEL_DIVIDER_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },

        // ------------------------------------------------------------
        // Work Schedule page styles
        // ------------------------------------------------------------
        'roleValues.WORK_SCHEDULE_COMMENT_BOX_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.WORK_SCHEDULE_ON_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.WORK_SCHEDULE_ON_SELECTED_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_050 },
        'roleValues.WORK_SCHEDULE_OFF_BACKGROUND_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.WORK_SCHEDULE_OFF_SELECTED_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_060 },
        'roleValues.WORK_SCHEDULE_CALENDAR_HEADER_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },
        'roleValues.WORK_SCHEDULE_CALENDAR_DAY_OFF_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },
        'roleValues.WORK_SCHEDULE_CALENDAR_DAY_ON_BACKGROUND': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel100Color,
        },
        'roleValues.WORK_SCHEDULE_CALENDAR_DAY_TODAY_BACKGROUND': { value: `rgba(${basicsValues.color.YELLOW}, 1)` },
        'roleValues.WORK_SCHEDULE_CALENDAR_WEEK_SELECTED_BACKGROUND': { value: basicsValues.color.BLUE_030 },

        // ------------------------------------------------------------
        // Badge Styles
        // ------------------------------------------------------------
        'roleValues.BADGE_MODIFIED_DATA_BADGE_COLOR': { value: basicsValues.color.BLACK },
        'roleValues.BADGE_MODIFIED_DATA_BADGE_BACKGROUND_COLOR': { value: basicsValues.color.BLUE_040 },
        // ------------------------------------------------------------

        // NSX Panel Styles
        // ------------------------------------------------------------
        'roleValues.NSX_PANEL_BACKGROUND': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.NSX_PANEL_BOX_SHADOW': { value: `(0 0 20px ${blackThemeRoleValues.COLOR_SHADOW})` },

        // Table Overlay Styles
        // ------------------------------------------------------------
        'roleValues.TABLE_OVERLAY_HEADER_BG_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },
        'roleValues.TABLE_OVERLAY_TOOLBAR_BG_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.TABLE_OVERLAY_BODY_BG_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel250Color },
        'roleValues.TABLE_OVERLAY_FOOTER_BG_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel250Color },

        // Table Styles
        // --------------------------------------------------
        'roleValues.TABLE_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.WHITE}, .1)`,
        },
        'roleValues.TABLE_HEADER_BORDER': {
            value: `${basicsValues.borderWidth.HAIRLINE} solid rgba(${basicsValues.color.WHITE}, .25)`,
        },

        // Two Pane Styles
        // ------------------------------------------------------------
        'roleValues.TWO_PANE_VIEW_SCROLL_CONTAINER_BG_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.TWO_PANE_VIEW_NAVIGATION_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel250Color },
        'roleValues.TWO_PANE_VIEW_NAVIGATION_TAB_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel200Color,
        },
        'roleValues.TWO_PANE_VIEW_NAVIGATION_AWAY_HIGHLIGHT_BG_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel250Color,
        },

        // Pipeline Summary Styles
        // ------------------------------------------------------------
        'roleValues.PIPELINE_SUMMARY_BG_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel100Color },
        'roleValues.PIPELINE_SUMMARY_SELECTED_BG_COLOR': {
            value: blackThemeRoleValues.colorScheme.surfaceLevel300Color,
        },

        // Field Property Summary Styles
        // ------------------------------------------------------------
        'roleValues.FIELD_PROPERTIES_PANEL_BG_COLOR': { value: blackThemeRoleValues.colorScheme.surfaceLevel200Color },

        // Trial Banner
        // TODO: This colour does not exist in current SGDS, replace once it exists
        'roleValues.TRIAL_BANNER_BUTTON_BORDER_COLOR': { value: `rgba(${basicsValues.color.WHITE}, 0.5)` },
    };
};

const shotgunRoles = {
    DEFAULT: makeDefaultTheme({
        roleValues: designLanguage.getRoleValuesForTheme('DEFAULT'),
        basicsValues: designLanguage.getBasicsValues(),
    }),
    BLACK: makeBlackTheme({
        roleValues: designLanguage.getRoleValuesForTheme('BLACK'),
        basicsValues: designLanguage.getBasicsValues(),
    }),
};

export default shotgunRoles;
