import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TextLink } from '@sgds/react';
import { bindCache } from 'sg/util/react_helpers';

import LoginFormText from './LoginFormText';
import LoginFormButton from './LoginFormButton';
import LoginFormFooter from './LoginFormFooter';
import LinkedAccountChoiceSet from './LinkedAccountChoiceSet';

class SamlAccountLinkForm extends PureComponent {
    constructor(props) {
        super(props);
        this.bind = bindCache(this);
    }

    render() {
        const { samlAccounts, samlEmail, samlLogin } = this.props;
        const hasSingleMatch = samlAccounts.length === 1;
        return (
            <form action="/saml/verify_link" method="post">
                <LoginFormText>
                    {hasSingleMatch && i18next.t('components.login.account_single_text')}
                    {!hasSingleMatch && i18next.t('components.login.account_multi_text')}
                </LoginFormText>
                <LoginFormText smallText>
                    {i18next.t('components.login.account_hint')}
                </LoginFormText>
                <LinkedAccountChoiceSet
                    inputName="old_login"
                    autoFocus={true}
                    accounts={samlAccounts.map(i => {
                        return {
                            inputValue: i.login,
                            image: i.image_url,
                            text: i.name,
                            subText: i.login,
                        };
                    })}
                />
                <LoginFormButton name="commit" type="submit">
                    {i18next.t('components.login.link_account_button')}
                </LoginFormButton>
                <input type="hidden" name="ignore_browser_check" defaultValue="1" />
                <input type="hidden" name="saml_login" defaultValue={samlLogin} />
                <input type="hidden" name="saml_email" defaultValue={samlEmail} />
                <LoginFormFooter>
                    <TextLink onClick={() => (window.location.pathname = '/saml/saml_linking_skip')}>
                        {i18next.t('components.login.dont_want')}
                    </TextLink>
                </LoginFormFooter>
            </form>
        );
    }
}

SamlAccountLinkForm.propTypes = {
    samlAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            login: PropTypes.string.isRequired,
            image_url: PropTypes.string,
        }).isRequired
    ).isRequired,
    samlEmail: PropTypes.string.isRequired,
    samlLogin: PropTypes.string.isRequired,
};

export default SamlAccountLinkForm;
