import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { bindCache } from 'sg/util/react_helpers';

import LoginFormText from './LoginFormText';
import LoginFormButton from './LoginFormButton';
import LoginFormFooterLink from './LoginFormFooterLink';
import LoginFormFooter from './LoginFormFooter';
import LinkedAccountChoiceSet from './LinkedAccountChoiceSet';

class AutodeskIdentitySelectForm extends PureComponent {
    constructor(props) {
        super(props);
        this.bind = bindCache(this);
        this.state = {
            submitted: false,
        };
        // Bindings
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    render() {
        const { csrfToken, accountInfos } = this.props;
        const { submitted } = this.state;
        return (
            <form method="post" onSubmit={this.onFormSubmit}>
                <LoginFormText>
                    {i18next.t('components.login.autodesk_identity_select_message')}
                </LoginFormText>
                <LinkedAccountChoiceSet
                    inputName="user_id"
                    autoFocus={true}
                    accounts={accountInfos.map(i => {
                        return {
                            inputValue: i.id,
                            image: i.image_url,
                            text: i.role,
                            subText: i.projects.join(', '),
                        };
                    })}
                />
                <LoginFormButton type="submit" isDisabled={submitted} onClick={() => {}}>
                    {submitted
                        ? i18next.t('components.login.signing_in_button')
                        : i18next.t('components.login.sign_in_button')}
                </LoginFormButton>
                <input type="hidden" name="csrf_token" defaultValue={csrfToken} />
                <LoginFormFooter>
                    <LoginFormFooterLink href="/autodesk_identity/link">
                        {i18next.t('components.login.link_another_account')}
                    </LoginFormFooterLink>
                </LoginFormFooter>
            </form>
        );
    }

    onFormSubmit(ev) {
        // Prevent CSRF trigger on double-click
        if (this.state.submitted) {
            ev.preventDefault();
            return;
        }

        this.setState({ submitted: true });
    }
}

AutodeskIdentitySelectForm.propTypes = {
    csrfToken: PropTypes.string.isRequired,
    accountInfos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            image_url: PropTypes.string,
            role: PropTypes.string.isRequired,
            projects: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired
    ).isRequired,
};

export default AutodeskIdentitySelectForm;
