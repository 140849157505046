import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormCodeInput from './LoginFormCodeInput';
import LoginFormText from './LoginFormText';
import LoginFormButton from './LoginFormButton';
import LoginFormFooterLink from './LoginFormFooterLink';
import LoginFormFooterLinkDivider from './LoginFormFooterLinkDivider';
import LoginFormFooter from './LoginFormFooter';
import { SCREEN_GETTING_HELP } from './ScreenState';
import { bindCache } from 'sg/util/react_helpers';

class TwoFactorLoginForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            useBackupCode: false,
        };
        this.bind = bindCache(this);
    }

    toggleUseBackupCode() {
        this.setState(({ useBackupCode }) => ({
            useBackupCode: !useBackupCode,
        }));
    }

    handleGetHelp() {
        this.props.setLoginState({
            screenState: SCREEN_GETTING_HELP,
        });
    }

    render() {
        const { useBackupCode } = this.state;
        return (
            <form action="/user/submit_two_factor_auth" method="post">
                <LoginFormText>
                    {useBackupCode
                        ? i18next.t('components.login.two_fa_enter_code')
                        : i18next.t('components.login.two_fa_message')}
                </LoginFormText>
                <LoginFormCodeInput
                    placeholder={i18next.t('components.login.two_fa_enter_code_label')}
                    id="user_auth_token"
                    name="user[auth_token]"
                />
                <LoginFormButton name="commit" type="submit">
                    {i18next.t('components.login.two_fa_verify_button')}
                </LoginFormButton>
                <LoginFormFooter>
                    <LoginFormFooterLink onClick={this.bind(this.toggleUseBackupCode)}>
                        {useBackupCode
                            ? i18next.t('components.login.two_fa_use_google_app')
                            : i18next.t('components.login.two_fa_use_code')}
                    </LoginFormFooterLink>
                    <LoginFormFooterLinkDivider />
                    <LoginFormFooterLink onClick={this.bind(this.handleGetHelp)}>
                        {i18next.t('components.login.two_fa_get_help')}
                    </LoginFormFooterLink>
                </LoginFormFooter>
            </form>
        );
    }
}

TwoFactorLoginForm.propTypes = {
    setLoginState: PropTypes.func.isRequired,
};

export default TwoFactorLoginForm;
