import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Text, TextLink, SvgIcon } from '@sgds/react';
import { memoize } from 'sg/util/react_helpers';

import withDesignLanguageContext from './withDesignLanguageContext';

const warningStyle = memoize((color, marginTop) => ({
    color,
    marginTop,
}));

class UnapprovedBrowserNotice extends PureComponent {
    render() {
        const { basicsValues, roleValues } = this.props.designLanguageContext;
        // note we need to keep the browser_not_approved className
        // because RV uses this to suppress the warning
        return (
            <div className="browser_not_approved">
                <div style={warningStyle(roleValues.COLOR_FEEDBACK_WARNING, basicsValues.spacing.MEDIUM)}>
                    <Text>
                        <SvgIcon iconId={basicsValues.icon.ISSUE_16} />
                        <p>
                            {this.props.isBrowserNotSupported
                                ? i18next.t('components.login.browser_not_supported_message')
                                : i18next.t('components.login.browser_old_message')}
                        </p>
                        <p>
                            <TextLink href="https://support.shotgunsoftware.com/entries/23533197">
                                {i18next.t('components.login.learn_more')}
                            </TextLink>
                        </p>
                    </Text>
                </div>
            </div>
        );
    }
}

UnapprovedBrowserNotice.propTypes = {
    isBrowserNotSupported: PropTypes.bool.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(UnapprovedBrowserNotice);
