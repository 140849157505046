import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Text } from '@sgds/react';

import withDesignLanguageContext from './withDesignLanguageContext';

import LoginFormText from './LoginFormText';
import { bindCache } from 'sg/util/react_helpers';
import { loginWidth } from './styleConstants';

// TODO: investigate if we can generalize the required styles for this component
// so they aren't "form styles"

class SamlAccountMergeProgress extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            progress: props.initialProgress,
        };
        this.bind = bindCache(this);
    }

    componentDidMount() {
        this.pollProgress();
    }

    componentWillUnmount() {
        clearTimeout(this.pollTimeout);
        this.unmounted = true;
    }

    pollProgress() {
        Ext.Ajax.request({
            url: `user_merge_progress?user_id=${this.props.userId}`,
            success: () => {
                window.location = '/user/login';
            },
            failure: response => {
                if (this.unmounted) {
                    return;
                }
                this.setState({
                    progress: JSON.parse(response.responseText).progress,
                });
                this.pollTimeout = setTimeout(this.bind(this.pollProgress), 3000);
            },
        });
    }

    render() {
        const { progress } = this.state;
        const { basicsValues, roleValues } = this.props.designLanguageContext;
        return (
            <Fragment>
                <LoginFormText>
                    {i18next.t('components.login.merge_login_message')}
                </LoginFormText>
                <progress
                    id="saml_progress_bar"
                    style={{ width: loginWidth, marginTop: basicsValues.spacing.LARGE }}
                    max={100}
                    defaultValue={progress}
                />
                <div style={{ textAlign: 'center' }}>
                    <Text>
                        {progress}
                        %{' '}
                        <span style={{ color: roleValues.COLOR_TEXT_SUBTLE }}>
                            {i18next.t('components.login.merge_estimated')}
                        </span>
                    </Text>
                </div>
                {/* TODO: we should use a design system TextLink instead of an a tag..
                but it's a part of the i18n string... */}
                <LoginFormText>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: i18next.t('components.login.merge_contact_url', {
                                markup_start:
                                    `<a style="color:${roleValues.COLOR_TEXT_LINK}" ` +
                                    'href="https://support.shotgunsoftware.com">',
                                markup_end: '</a>',
                            }),
                        }}
                    />
                </LoginFormText>
            </Fragment>
        );
    }
}

SamlAccountMergeProgress.propTypes = {
    initialProgress: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(SamlAccountMergeProgress);
