import React, { PureComponent } from 'react';
import withDesignLanguageContext from './withDesignLanguageContext';

class LoginFormFooter extends PureComponent {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                {this.props.children}
            </div>
        );
    }
}

export default withDesignLanguageContext(LoginFormFooter);
