import React from 'react';
import ReactDOM from 'react-dom';
import qs from 'qs';

import * as ScreenState from './ScreenState';
import Login from './Login';

const DebugButtons = ({ screenStates = [], render = () => undefined }) =>
    <div>
        {screenStates.map(screenState =>
            <button key={screenState} onClick={() => render(screenState)}>
                {screenState}
            </button>
        )}
    </div>;

SG.Login = class extends SG.Component {
    init_component() {
        // remove the redundant server-generated version DIV:
        const elm = document.getElementById('shotgun_version');
        if (elm && elm.parentNode) {
            elm.parentNode.removeChild(elm);
        }

        let container = document.getElementById('container');
        if (!container) {
            container = document.createElement('div');
            container.id = 'container';
            document.getElementsByTagName('body')[0].appendChild(container);
        }

        this.container = container;

        this.render(ScreenState.getInitialState(this));

        const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (Boolean(query.debug_screen_state)) {
            this.render_debug_buttons();
        }
    }

    render(screenState) {
        ReactDOM.unmountComponentAtNode(this.container);
        ReactDOM.render(<Login screenState={screenState} config={this} />, this.container);
    }

    render_debug_buttons() {
        const screenStates = Object.keys(ScreenState).filter(s => s.indexOf('SCREEN_') === 0);

        const buttons_container = document.createElement('div');

        buttons_container.style.position = 'fixed';
        buttons_container.style.top = 0;
        buttons_container.style.padding = '2em';
        document.body.appendChild(buttons_container);

        ReactDOM.render(
            <DebugButtons screenStates={screenStates} render={this.render.bind(this)} />,
            buttons_container
        );
    }
};
