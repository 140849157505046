import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Card } from '@sgds/react';

import withDesignLanguageContext from './withDesignLanguageContext';

import { memoize } from 'sg/util/react_helpers';

const cardStyle = memoize((margin, padding) => ({
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    margin,
    padding,
}));

class AccountChoiceContainer extends PureComponent {
    constructor(props) {
        super(props);
        // Bindings
        this.onCardClick = this.onCardClick.bind(this);
    }

    render() {
        const { inputValue, selected, children, designLanguageContext } = this.props;
        const { basicsValues } = designLanguageContext;
        return (
            <Card
                isSelected={selected}
                onClick={this.onCardClick}
                style={cardStyle(basicsValues.spacing.X_SMALL, basicsValues.spacing.X_SMALL)}
            >
                {children}
            </Card>
        );
    }

    onCardClick() {
        this.props.onSelected(this);
    }
}

AccountChoiceContainer.propTypes = {
    inputValue: PropTypes.number.isRequired,
    selected: PropTypes.bool,
    onSelected: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(AccountChoiceContainer);
