import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withDesignLanguageContext from './withDesignLanguageContext';

import { Thumbnail, Text } from '@sgds/react';

import AccountChoiceContainer from './AccountChoiceContainer';
import { memoize } from 'sg/util/react_helpers';

const accountInfoStyle = memoize(marginLeft => ({
    marginLeft,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
}));

const nameStyle = memoize(color => ({
    color,
}));

const thumbnailStyle = {
    width: '3rem',
    height: '3rem',
    flexShrink: 0,
};

class LinkedAccountChoice extends PureComponent {
    constructor(props) {
        super(props);
        this.renderImage = function(props2) {
            // @NOTE RenderPlaceholder has no way to change the image. Until then... This may do
            if (props2.source === '#default') {
                props2.style.backgroundColor = props.designLanguageContext.basicsValues.color.GRAY_030; // Closest to #ccced2;
                props2.source = '/images/default_entity_thumbs/default_HumanUser_thumb.png';
            }
            return Thumbnail.defaultProps.renderImage(props2);
        };
    }

    render() {
        const {
            inputName,
            inputValue,
            selected,
            text,
            subText,
            image,
            autoFocus,
            onSelected,
            designLanguageContext,
        } = this.props;
        const { basicsValues, roleValues } = designLanguageContext;
        return (
            <AccountChoiceContainer inputValue={inputValue} selected={selected} onSelected={onSelected}>
                <input
                    style={{ position: 'fixed', opacity: 0 }}
                    type="radio"
                    name={inputName}
                    value={inputValue}
                    autoFocus={autoFocus}
                    checked={selected}
                    readOnly={true}
                />
                <Thumbnail
                    style={thumbnailStyle}
                    imageSource={image || '#default'}
                    aspectRatio={1}
                    renderImage={this.renderImage}
                />
                <div style={accountInfoStyle(basicsValues.spacing.X_LARGE)}>
                    <div style={{ display: 'flex' }}>
                        <Text
                            style={{
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '14rem', // @TODO Would be nice to not have to do this
                            }}
                            title={text}
                        >
                            {text}
                        </Text>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Text
                            style={Object.assign(nameStyle(roleValues.COLOR_TEXT_SUBTLE), {
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '14rem', // @TODO Would be nice to not have to do this
                            })}
                            title={subText}
                        >
                            {subText}
                        </Text>
                    </div>
                </div>
            </AccountChoiceContainer>
        );
    }
}

LinkedAccountChoice.propTypes = {
    inputName: PropTypes.string.isRequired,
    inputValue: PropTypes.number.isRequired,
    selected: PropTypes.bool,
    text: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    image: PropTypes.string,
    autoFocus: PropTypes.bool,
    onSelected: PropTypes.func.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(LinkedAccountChoice);
