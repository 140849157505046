import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import LoginFormText from './LoginFormText';
import LoginFormButton from './LoginFormButton';
import { SCREEN_GETTING_HELP_DONE } from './ScreenState';
import { bindCache } from 'sg/util/react_helpers';

class GettingHelp extends PureComponent {
    constructor(props) {
        super(props);
        this.bind = bindCache(this);
    }

    handleClick() {
        var request = {
            url: '/user/request_help',
            params: {
                csrf_token: getCookie('csrf_token'),
            },
            promise_scope: this,
        };

        sg_send_request(request).then(
            server_response => {
                var response = JSON.parse(server_response.response);

                if (response.successful) {
                    this.props.setLoginState({
                        screenState: SCREEN_GETTING_HELP_DONE,
                    });
                } else {
                    this.props.setLoginState({
                        flashError: response.error,
                    });
                }
            },
            server_response => {
                SG.server_error({ connection_response: server_response });
            }
        );
    }

    render() {
        return (
            <Fragment>
                <LoginFormText>
                    {i18next.t('components.login.get_help_message')}
                </LoginFormText>
                <LoginFormButton name="commit" type="submit" onClick={this.bind(this.handleClick)}>
                    {i18next.t('components.login.get_help_button')}
                </LoginFormButton>
            </Fragment>
        );
    }
}

GettingHelp.propTypes = {
    setLoginState: PropTypes.func.isRequired,
};

export default GettingHelp;
