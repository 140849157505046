// constants enumerating different screen states
export const SCREEN_SAML_LOGIN = 'SCREEN_SAML_LOGIN';
export const SCREEN_SAML_LINK = 'SCREEN_SAML_LINK';
export const SCREEN_SAML_NO_MATCH_LINK = 'SCREEN_SAML_NO_MATCH_LINK';
export const SCREEN_SAML_LINK_PASSWORD = 'SCREEN_SAML_LINK_PASSWORD';
export const SCREEN_SAML_ACCOUNT_MERGE_PROGRESS = 'SCREEN_SAML_ACCOUNT_MERGE_PROGRESS';
export const SCREEN_AUTODESK_IDENTITY_LOGIN = 'SCREEN_AUTODESK_IDENTITY_LOGIN';
export const SCREEN_AUTODESK_IDENTITY_LINK = 'SCREEN_AUTODESK_IDENTITY_LINK';
export const SCREEN_AUTODESK_IDENTITY_SELECT = 'SCREEN_AUTODESK_IDENTITY_SELECT';
export const SCREEN_TWO_FACTOR_LOGIN = 'SCREEN_TWO_FACTOR_LOGIN';
export const SCREEN_RESET_PASSWORD = 'SCREEN_RESET_PASSWORD';
export const SCREEN_RESET_PASSWORD_DONE = 'SCREEN_RESET_PASSWORD_DONE';
export const SCREEN_RESET_LOGIN = 'SCREEN_RESET_LOGIN';
export const SCREEN_RESET_LOGIN_DONE = 'SCREEN_RESET_LOGIN_DONE';
export const SCREEN_PASSWORD_LOGIN = 'SCREEN_PASSWORD_LOGIN';
export const SCREEN_GETTING_HELP = 'SCREEN_GETTING_HELP';
export const SCREEN_GETTING_HELP_DONE = 'SCREEN_GETTING_HELP_DONE';

export function getInitialState(sgLoginComponent) {
    if (sgLoginComponent.saml && !sgLoginComponent.alternate_login && !sgLoginComponent.forgot_password) {
        if (sgLoginComponent.saml_account_linking_password) {
            return SCREEN_SAML_LINK_PASSWORD;
        } else if (typeof sgLoginComponent.merge_in_progress === 'number') {
            return SCREEN_SAML_ACCOUNT_MERGE_PROGRESS;
        } else if (sgLoginComponent.saml_account_linking) {
            if (sgLoginComponent.saml_matching_accounts.length) {
                return SCREEN_SAML_LINK;
            }
            return SCREEN_SAML_NO_MATCH_LINK;
        } else {
            return SCREEN_SAML_LOGIN;
        }
    } else if (
        sgLoginComponent.autodesk_identity &&
        !sgLoginComponent.forgot_password &&
        !sgLoginComponent.alternate_login
    ) {
        return SCREEN_AUTODESK_IDENTITY_LOGIN;
    } else if (sgLoginComponent.autodesk_identity_link) {
        return SCREEN_AUTODESK_IDENTITY_LINK;
    } else if (sgLoginComponent.autodesk_identity_select) {
        return SCREEN_AUTODESK_IDENTITY_SELECT;
    } else if (sgLoginComponent.prompt_two_factor_auth) {
        return SCREEN_TWO_FACTOR_LOGIN;
    } else if (sgLoginComponent.forgot_password_done_done) {
        return SCREEN_RESET_PASSWORD_DONE;
    } else if (sgLoginComponent.forgot_password) {
        return SCREEN_RESET_PASSWORD;
    } else if (sgLoginComponent.forgot_login_done) {
        return SCREEN_RESET_LOGIN_DONE;
    } else if (sgLoginComponent.forgot_login) {
        return SCREEN_RESET_LOGIN;
    } else if (sgLoginComponent.getting_help_done) {
        return SCREEN_GETTING_HELP_DONE;
    } else if (sgLoginComponent.getting_help) {
        return SCREEN_GETTING_HELP;
    } else {
        return SCREEN_PASSWORD_LOGIN;
    }
}

export function getTitle({ screenState, autodeskIdentityUserInfo }) {
    switch (screenState) {
        case SCREEN_SAML_LINK:
        case SCREEN_SAML_LINK_PASSWORD:
        case SCREEN_AUTODESK_IDENTITY_LINK:
            return i18next.t('components.login.your_account_title');
        case SCREEN_AUTODESK_IDENTITY_SELECT:
            /* eslint no-case-declarations: 0 */
            let hour = new Date().getHours();
            let key_suffix = hour < 12 ? 'morning' : hour < 17 ? 'afternoon' : 'evening';
            return i18next.t(`components.login.account_select_title_${key_suffix}`, {
                markup_line_break: '<br/>',
                first_name: autodeskIdentityUserInfo.first_name,
                last_name: autodeskIdentityUserInfo.last_name,
            });
        case SCREEN_RESET_PASSWORD:
        case SCREEN_RESET_PASSWORD_DONE:
            return i18next.t('components.login.forgot_password_title');
        case SCREEN_RESET_LOGIN:
        case SCREEN_RESET_LOGIN_DONE:
            return i18next.t('components.login.forgot_login_title');
        case SCREEN_GETTING_HELP:
        case SCREEN_GETTING_HELP_DONE:
            return i18next.t('components.login.get_help_title');
        default:
            return null;
    }
}

export function hasBackToLoginButton(screenState) {
    switch (screenState) {
        case SCREEN_SAML_LINK_PASSWORD:
        case SCREEN_RESET_PASSWORD_DONE:
        case SCREEN_RESET_PASSWORD:
        case SCREEN_RESET_LOGIN_DONE:
        case SCREEN_RESET_LOGIN:
        case SCREEN_GETTING_HELP_DONE:
        case SCREEN_GETTING_HELP:
            return true;
        default:
            return false;
    }
}

export function hasBackToTwoFactorButton(screenState) {
    return screenState === SCREEN_GETTING_HELP || screenState === SCREEN_GETTING_HELP_DONE;
}
