import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoginFormText from './LoginFormText';
import LoginFormInput from './LoginFormInput';
import LoginFormButton from './LoginFormButton';
import { bindCache } from 'sg/util/react_helpers';

class ResetLoginForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.bind = bindCache(this);
    }

    handleEmailRequest(e) {
        if (e.target instanceof HTMLFormElement) {
            e.preventDefault();
        }
        if (this.state.email) {
            this.props.onEmailRequest(this.state.email);
        }
    }

    handleInput(value) {
        this.setState({
            email: value,
        });
    }

    render() {
        return (
            <form onSubmit={this.bind(this.handleEmailRequest)}>
                <LoginFormText>
                    {i18next.t('components.login.forgot_login_message')}
                </LoginFormText>
                <LoginFormInput
                    label={i18next.t('components.login.email_label')}
                    id="user_email"
                    value={this.state.email}
                    name="user[email]"
                    onChange={this.bind(this.handleInput)}
                />
                <LoginFormButton name="commit" type="button" onClick={this.bind(this.handleEmailRequest)}>
                    {i18next.t('components.login.request_login_button')}
                </LoginFormButton>
            </form>
        );
    }
}

ResetLoginForm.propTypes = {
    onEmailRequest: PropTypes.func.isRequired,
};

export default ResetLoginForm;
