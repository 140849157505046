import React from 'react';

import { DesignLanguageContext } from '@sgds/react';
import { shotgunDesignLanguage } from './shotgun_design_language';

const ShotgunDesignLanguageContext = {
    Provider: props => <DesignLanguageContext.Provider {...props} designLanguage={shotgunDesignLanguage} />,
    Consumer: DesignLanguageContext.Consumer,
};

export default ShotgunDesignLanguageContext;
