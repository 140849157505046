import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sgds/react';

import withDesignLanguageContext from './withDesignLanguageContext';

import { memoize } from 'sg/util/react_helpers';

const textStyle = memoize((marginTop, fontSize) => ({
    marginTop,
    fontSize,
}));

class LoginFormText extends PureComponent {
    render() {
        const { smallText, designLanguageContext, ...rest } = this.props;
        const { basicsValues } = designLanguageContext;
        return (
            <div
                style={textStyle(
                    smallText ? basicsValues.spacing.MEDIUM : basicsValues.spacing.X_LARGE,
                    smallText ? basicsValues.fontSize.X_SMALL : basicsValues.fontSize.BASE
                )}
            >
                <Text {...rest} />
            </div>
        );
    }
}

LoginFormText.propTypes = {
    smallText: PropTypes.bool.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

LoginFormText.defaultProps = {
    smallText: false,
};

export default withDesignLanguageContext(LoginFormText);
