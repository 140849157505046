export function bindCache(instance) {
    const cache = new Map();
    const boundFnSymbol = Symbol();

    return function bind(fn, ...args) {
        const c = findSubcacheForArgs(cache, [fn, ...args]);
        if (!c.has(boundFnSymbol)) {
            c.set(boundFnSymbol, fn.bind(instance, ...args));
        }
        return c.get(boundFnSymbol);
    };
}

export function memoize(fn) {
    const cache = new Map();
    const resultSymbol = Symbol();

    return function memoized(...args) {
        const c = findSubcacheForArgs(cache, args);
        if (!c.has(resultSymbol)) {
            c.set(resultSymbol, fn(...args));
        }
        return c.get(resultSymbol);
    };
}

function findSubcacheForArgs(cache, args) {
    let c = cache;
    for (const key of args) {
        if (!c.has(key)) {
            c.set(key, new Map());
        }
        c = c.get(key);
    }
    return c;
}

/**
 * Helper to filter out props based component's PropTypes definition.
 * Effectively returns the extra/roaming ones only.
 * Useful to keep it up to date by making it a consulted source of truth.
 * @param  {Object} options.props     All props received by the component.
 * @param  {Object} options.component Component that has propType definition.
 * @return {Object}                   Filtered props.
 */
export function getRoamingProps({ props, component }) {
    const ownKeys = Object.keys(component.propTypes);
    return Object.fromEntries(Object.entries(props).filter(([key, val]) => !ownKeys.includes(key)));
}
