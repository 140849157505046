import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withDesignLanguageContext from './withDesignLanguageContext';

import { memoize } from 'sg/util/react_helpers';

const wrapperStyle = memoize(marginTop => ({
    marginTop,
    maxHeight: '11rem',
    overflowX: 'none',
    overflowY: 'auto',
}));

class ScrollableContent extends PureComponent {
    render() {
        const { maxHeight, designLanguageContext, children } = this.props;
        return (
            <div style={wrapperStyle(designLanguageContext.basicsValues.spacing.X_LARGE)}>
                {children}
            </div>
        );
    }
}

ScrollableContent.propTypes = {
    children: PropTypes.node.isRequired,
    designLanguageContext: PropTypes.shape({
        basicsValues: PropTypes.object,
        roleValues: PropTypes.object,
    }),
};

export default withDesignLanguageContext(ScrollableContent);
